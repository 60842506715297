/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import FeatureTitleStyles from './FeatureTitleStyles';
import { BodyText, H2Text, H3Text } from '../../../../../../components/ui';

const FeatureTitle = ({ title, subTitle, message, image }) => {
  const theme = useTheme();
  const classes = FeatureTitleStyles(theme);
  
  return (
    <Box css={classes.textContainer}>
      <H2Text size={theme.typography.title} css={classes.title}>{title}</H2Text>
      <H3Text size={theme.typography.titleSmall} css={classes.subTitle}>{subTitle}</H3Text>
      <BodyText 
        size={theme.typography.body}
        css={classes.message}
        dangerouslySetInnerHTML={{ __html: message }}
      ></BodyText>
      <img src={image} alt={title} css={classes.image} />
    </Box>
  )
};

export default FeatureTitle;
