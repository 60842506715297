/** @jsxImportSource @emotion/react */
import React from 'react';
import {
  useTheme, Box, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, 
} from '@mui/material';
import ConfirmDialogStyles from './ConfirmDialogStyles';
import { BodyText, CustomButton } from '../../../../../../components/ui';

const ConfirmDialog = ({ formData, open, handleDialogClose, handleDialogSubmit }) => {
  const theme = useTheme();
  const classes = ConfirmDialogStyles(theme);

  return (
    <Dialog open={open} onClose={handleDialogClose} PaperProps={{ sx: classes.dialog }}>
      <DialogTitle sx={classes.dialogTitle}>
        入力内容の確認
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <DialogContentText sx={classes.dialogContentText}>
          以下の内容で送信します。よろしいですか？
        </DialogContentText>
        <Box css={classes.contentContainer}>
            <BodyText size={theme.typography.body}>お名前：</BodyText>
            <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.name}</BodyText>
        </Box>
        <Box css={classes.contentContainer}>
            <BodyText size={theme.typography.body}>電話番号：</BodyText>
            <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.phone}</BodyText>
        </Box>
        <Box css={classes.contentContainer}>
            <BodyText size={theme.typography.body}>メールアドレス：</BodyText>
            <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.email}</BodyText>
        </Box>
        <Box css={classes.contentContainer}>
            <BodyText size={theme.typography.body}>優先連絡手段：</BodyText>
            <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>
              {formData.preContact}
            </BodyText>
        </Box>
        <Box css={classes.contact}>
            <BodyText size={theme.typography.body}>お問い合わせ内容：</BodyText>
            <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.message}</BodyText>
        </Box>
      </DialogContent>
      <DialogActions css={classes.dialogActions}>
        <CustomButton
            size={classes.customButtonSize}
            width={classes.customButtonWidth}
            variant="button"
            onClick={handleDialogClose}
            colorVariant={'grayBackground'}
        >戻る</CustomButton>
        <CustomButton
            size={classes.customButtonSize}
            width={classes.customButtonWidth}
            variant="button"
            onClick={handleDialogSubmit}
        >送信</CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
