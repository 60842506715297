import { css } from '@emotion/react';

const headerSectionStyles = (theme) => ({
    container: css({
        width: '100%',
        marginTop: `-${theme.margin.top}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: `0 ${theme.margin.md}`,
        },
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.margin.top} ${theme.margin.sm} 0 ${theme.margin.sm}`,
        },
    }),
    wrapper: css({
        position: 'relative',
        width: '100%',
        maxWidth: theme.width.max,
        height: 'auto',
        aspectRatio: '10 / 6',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            aspectRatio: theme.aspectRatio.tablet,
        },
        [theme.breakpoints.down('sm')]: {
            aspectRatio: theme.aspectRatio.mobile,
        },
    }),
    backgroundImage: css({
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: -1,
        [theme.breakpoints.down('md')]: {
            content: `url('/images/100TopPage/101HeaderSectionBackGroundMedium.png')`,
        },
        [theme.breakpoints.down('sm')]: {
            content: `url('/images/100TopPage/101HeaderSectionBackGroundSmall.png')`,
        },
    }),
    title: css({
        marginBottom: '20px',
        fontSize: '96px',
        whiteSpace: 'pre-wrap',
        [theme.breakpoints.down('md')]: {
            fontSize: '85px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '80px',
        },
    }),
    highlighted: css({
        color: theme.palette.primary.main,
    }),
    rotatingText: css({
        width: '290px',
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            width: '240px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '220px',
        },
        whiteSpace: 'pre-wrap',
    }),
    fontBold: css({
        fontWeight: 'bold',
    }),
});

export default headerSectionStyles;
