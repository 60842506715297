import { css, keyframes } from '@emotion/react';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const navItemStyles = (theme, isHovered) => ({
  navItem: css({
    position: 'relative',
    padding: '0px',
    cursor: 'pointer',
    '&:hover .subMenu': {
      display: 'block',
      animation: `${fadeIn} 0.3s ease-in`,
    },
  }),
  navLink: css({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:before': {
        borderRadius: '50%',
        width: '10px',
        height: '10px',
        content: '""',
        border: `2px solid ${theme.palette.border.sub}`,
        lineHeight: '1',
        marginRight: '5px',
        backgroundColor: isHovered ? theme.palette.primary.main : 'transparent',
    },
  }),
  box: css({
    display: 'block',
    position: 'absolute',
    top: '100%',
    left: '0',
    width: '100%',
    height: '30px',
  }),
  subMenu: css({
    display: isHovered ? 'block' : 'none',
    position: 'absolute',
    top: '200%',
    left: '-20px',
    width: '200px',
    background: 'white',
    border: '1px solid #ddd',
    borderRadius: '5px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '12px 15px 0px 15px',
    margin: '0 0 5px 0',
    zIndex: '1000',
    opacity: isHovered ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
    '&:hover': {
        display: 'block',
    }
  }),
  subMenuItem: css({
    padding: '0 0 12px 0',
  }),
  subItemNavLink: css({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover .arrow': {
      display: 'block',
    },
  }),
  arrow: css({
    display: 'none',
  }),
});

export default navItemStyles;
