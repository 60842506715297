import { css } from '@emotion/react';

const ContactFormStyles = (theme) => ({
  form: css({
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 30px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '20px',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 10px',
    },
  }),
  privacyPolicy: css({
    margin: '0 auto 20px auto',
  }),
  errorPrivacyPolicy: css({
    marginTop: '25px',
    paddingLeft: '20px',
  }),
  button: css({
    marginTop: 16,
  }),
  statusMessage: (isError) => css({
    marginTop: 16,
    color: isError ? 'red' : 'green',
  }),
  customButtonSize: 'small',
  customButtonWidth: '60%',
  customButtonMinWidth: '225px',
});

export default ContactFormStyles;
