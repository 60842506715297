import { useState } from 'react';

const useFAQ = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFAQ = () => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    toggleFAQ,
  };
};

export default useFAQ;
