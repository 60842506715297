/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { MessageSection } from '../../../../components/common';
import ValueSectionStyle from './ValueSectionStyle';

const ValueSection = () => {
    const theme = useTheme();
    const classes = ValueSectionStyle(theme);

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <MessageSection
                    title="価値" 
                    message='
                    １. 成長と教育  <br />
                    <span style="font-size: 15px;">子供から大人まで、一人ひとりの成長を支え、学びと楽しさを提供します。</span><br />
                    ２. 革新と創造  <br />
                    <span style="font-size: 15px;">ダンスや運動を通じて、新しい体験や価値を創造し続けます。</span><br />
                    ３. 健康第一  <br />
                    <span style="font-size: 15px;">すべての活動において、心と体の健康を最優先に考え、支えることに努めます。</span><br />
                    ４. 包括性と参加  <br />
                    <span style="font-size: 15px;">すべての人が安心して参加できる環境を提供し、多様なニーズに応えることを大切にします。</span><br />
                    ５. 地域貢献  <br />
                    <span style="font-size: 15px;">地域コミュニティーの健康と未来に貢献し、地域との強い絆を築きます。</span><br />
                    '
                    id="value"
                />
            </Box>
        </Box>
    );
};

export default ValueSection;
