/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { ListItem, List, Link, Box } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useTheme } from '@mui/material/styles';
import NavItemStyles from './NavItemStyles';
import { BodyText, H3Text } from '../../../../ui/CustomText/CustomText';

const NavItem = ({ title, url, subItems }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const classes = NavItemStyles(theme, isHovered);

  return (
    <ListItem
      css={classes.navItem}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Link href={url} css={classes.navLink} aria-label={title} rel="noopener noreferrer">
        <H3Text size={theme.typography.body}>{title}</H3Text>
      </Link>
      <Box css={classes.box}></Box>
      {subItems && (
        <div className="subMenu" css={classes.subMenu}>
          <List>
            {subItems.map((item, index) => (
              <ListItem key={index} css={classes.subMenuItem}>
                <Link href={item.url} css={classes.subItemNavLink} aria-label={item.text} rel="noopener noreferrer">
                  <BodyText size={theme.typography.body}>{item.text}</BodyText>
                  <ArrowRightAltIcon className="arrow" css={classes.arrow} fontSize="small" />
                </Link>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    </ListItem>
  );
};

export default NavItem;
