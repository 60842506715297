import { css } from '@emotion/react';

const ContactFormSectionStyles = (theme) => ({
  container: css({
    width: '100%',
    margin: '20px 0 100px 0',
  }),
  wrapper: css({
    width: '60%',
    maxWidth: '600px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  }),
  title: css({
    marginBottom: 16,
  }),
});

export default ContactFormSectionStyles;
