/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Benefit } from './components';
import { benefitsData } from '../../../../config'
import BenefitsSectionStyles from './BenefitsSectionStyles';
import { H2Text } from '../../../../components/ui';

const IntroductionBenefitsSection = () => {
    const theme = useTheme();
    const classes = BenefitsSectionStyles(theme);

    return (
        <Box css={classes.container} id='power'>
            <Box css={classes.wrapper}>
                <H2Text size={theme.typography.titleLarge} css={classes.title}>ダンスのメリット</H2Text>
                <Box css={classes.benefitsContainer}>
                    {benefitsData.map((item, index) => (
                    <Benefit
                        key={index}
                        title={item.title}
                        message={item.message}
                        image={item.src}
                        reverse={index % 2 === 1}
                    />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default IntroductionBenefitsSection;
