import { css } from '@emotion/react';

const AchievmentsSectionStyles = (theme) => ({
    container: css({
        width: '100%',
        padding: '40px',
        marginBottom: '40px'
    }),
    wrapper: css({
        width: '100%',
        maxWidth: theme.width.max,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    contents: css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '20px',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
        }, 
    }),
    title: css({
        display: 'flex',
        justifyContent: 'start',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'left',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            fontSize: '24px',
            textAlign: 'center',
            justifyContent: 'center',
        },
    }),
    description: css({
        marginBottom: '50px',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        },
    }),
});

export default AchievmentsSectionStyles;
