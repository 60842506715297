import { useState, useEffect, useCallback } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

const useTestimonialsSection = (testimonials) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isTransition, setIsTransition] = useState(false);
    const [isAutoScroll, setIsAutoScroll] = useState(true);
    const totalItems = testimonials.length;
    const copyCount = 4;

    const handleNext = useCallback(() => {
        if (!isTransition) {
            setIsTransition(true);
            setCurrentIndex((prevIndex) => prevIndex + 1);
        }
    }, [isTransition]);

    const handlePrev = useCallback(() => {
        if (!isTransition) {
            setIsTransition(true);
            setCurrentIndex((prevIndex) => prevIndex - 1);
        }
    }, [isTransition]);

    useEffect(() => {
        let interval;
        if (isAutoScroll) {
            interval = setInterval(() => {
                handleNext();
            }, 3000);
        }

        return () => clearInterval(interval);
    }, [isAutoScroll, handleNext]);

    const handleTransitionEnd = () => {
        setIsTransition(false);
        setCurrentIndex((prevIndex) => (totalItems + prevIndex) % totalItems);
    };

    const getSlideItems = () => {
        const extendedTestimonials = [
            ...testimonials.slice(-copyCount),
            ...testimonials,
            ...testimonials.slice(0, copyCount)
        ];
        return extendedTestimonials;
    };

    const handleNextButton = () => {
        setIsAutoScroll(false);
        handleNext();
    };

    const handlePrevButton = () => {
        setIsAutoScroll(false);
        handlePrev();
    };

    const calculateTransform = () => {
        if (isSmDown) {
            return `translate3d(${-88 * (currentIndex + copyCount)}%, 0, 0)`;
        } else if (isMdDown) {
            return `translate3d(${-42 * (currentIndex + copyCount)}%, 0, 0)`;
        } else {
            return `translate3d(${-30 * (currentIndex + copyCount)}%, 0, 0)`;
        }
    };

    const slideStyle = {
        transform: calculateTransform(),
        transition: isTransition ? 'transform 1s' : 'none',
    };

    return {
        handleTransitionEnd,
        slideStyle,
        getSlideItems,
        handleNextButton,
        handlePrevButton,
    };
};

export default useTestimonialsSection;
