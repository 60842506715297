import { css } from '@emotion/react';

const FAQSectionStyles = (theme) => ({
  container: css({
    width: '100%',
    margin: '20px 0 100px 0',
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '60%',
    maxWidth: theme.width.max,
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  }),
  title: css({
    padding: '100px 0 40px 0',
    fontWeight: 'bold',
  }),
});

export default FAQSectionStyles;
