import { css } from '@emotion/react';

const FooterNavStyles = ( theme ) => ({
     navContainer: css({
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(330px, 1fr))',
        marginBottom: '80px',
        width: '100%',
        maxWidth: theme.width.max,
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
        },
    }),
    navColumn: css({
        color: theme.palette.white.main,
        [theme.breakpoints.down('md')]: {
            marginBottom: '20px',
        },
    }),
    navLink: css({
        display: 'flex',
        textDecoration: 'none',
        alignItems: 'center',
        marginBottom: '15px',
        '&:hover': {
            '& svg': {
                transform: 'translate(3px, -3px)',
            },
        },
    }),
    navTitle: {
        fontWeight: 'bold',
        color: theme.palette.white.main,
        marginRight: '2px',
    },
    navIcon: css({
        width: '12px',
        height: '12px',
        color: theme.palette.white.main,
        transition: 'transform 0.2s ease',
    }),
    navItem: {
        color: theme.palette.white.main,
        display: 'block',
        marginBottom: '15px',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

export default FooterNavStyles;
