import { css } from '@emotion/react';

const drawerStyles = (theme) => ({
  drawer: css({
    '& .MuiPaper-root': {
      position: 'absolute',
      top: '65px',
      right: theme.margin.md,
      height: 'calc(80% - 120px)',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '15px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('sm')]: {
        top: '0px',
        right: '0px',
        height: '100%',
        width: '100%',
        borderRadius: '0px',
      },
    },
  }),
  drawerHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  drawerList: css({
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
  drawerItem: css({
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  }),
  MenuItemLinkText: css({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      borderBottomColor: theme.palette.primary.dark,
    },
  }),
  subMenuItem: css({
    paddingLeft: theme.spacing(4),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:before': {
        borderRadius: '50%',
        width: '5px',
        height: '5px',
        content: '""',
        border: `1px solid ${theme.palette.border.sub}`,
        lineHeight: '1',
        marginRight: '5px',
    },
  }),
  colorPrime: css({
    color: theme.palette.primary.main,
  }),
  closeButton: css({
    alignSelf: 'flex-end',
    margin: '0',
    padding: '0',
  }),
});

export default drawerStyles;
