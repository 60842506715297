/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Link, IconButton } from '@mui/material';
import { useTheme } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';
import BottomContainerStyles from './BottomContainerStyles';
import { BodyText, H3Text } from '../../../../ui';
import { urls } from '../../../../../config';

const BottomContainer = () => {
    const theme = useTheme();
    const classes = BottomContainerStyles(theme);

    return (
        <Box css={classes.bottomContainer}>
            <H3Text size={theme.typography.invisible}>Follow Us</H3Text>
            <Box css={classes.socialIcons}>
                <IconButton href={urls.instagram} target="_blank" rel="noopener">
                    <Instagram />
                </IconButton>
                <IconButton href={urls.facebook} target="_blank" rel="noopener">
                    <Facebook />
                </IconButton>
            </Box>
            <Box css={classes.rightContainer}>
                <Link href={urls.privacyPolicy} css={classes.privacyPolicy}>
                    <BodyText size={theme.typography.bodySmall}>Privacy Policy</BodyText>
                </Link>
                <BodyText size={theme.typography.bodySmall}>©RUKH 2024</BodyText>
            </Box>
        </Box>
    );
}

export default BottomContainer;
