/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BodyText, H2Text, CustomButton } from '../../../../components/ui';
import SolutionSectionStyles from './SolutionSectionStyles';
import { urls } from '../../../../config';
import { useNavigateToUrl } from '../../../../utils'

const BusinessSection1 = () => {
    const theme = useTheme();
    const classes = SolutionSectionStyles(theme);
    const navigateToUrl = useNavigateToUrl();

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <Box css={classes.content}>
                    <Box css={classes.textContainer}>
                        <H2Text size={theme.typography.title} css={classes.subTitle}>出張ダンス教室</H2Text>
                        <BodyText size={theme.typography.body} css={classes.description}>・幼稚園や保育園におけるダンス・運動プログラムの実施</BodyText>
                        <BodyText size={theme.typography.body} css={classes.description}>・幼児期の心身の発達を促すための体験型指導</BodyText>
                    </Box>
                    <Box css={classes.buttonContainer}>
                        <CustomButton
                            minWidth={classes.buttonMinWidth}
                            width={classes.buttonMinWidth}
                            onClick={() => navigateToUrl(urls.aboutTravelingDanceClasses)}
                        >
                            <BodyText style={classes.smallText}>詳しくはこちら</BodyText>
                        </CustomButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BusinessSection1;
