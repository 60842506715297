/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useTheme, Typography } from '@mui/material';
import useRotatingText from './UseRotatingText';

const RotatingText = ({ texts, interval, variant, size, css: customCss, ...props }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const theme = useTheme();
  const rotatingTextClasses = useRotatingText(theme, interval);

  useEffect(() => {
    const changeText = () => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };

    const intervalId = setInterval(changeText, interval);

    return () => clearInterval(intervalId);
  }, [texts, interval]);

  const combinedStyles = css({ 
    ...size,
    ...customCss,
  });

  return (
    <Typography 
      css={[combinedStyles, rotatingTextClasses.rotatingText]} 
      key={currentTextIndex}
      variant={variant}
      {...props}
    >
      {texts[currentTextIndex]}
    </Typography>
  );
};

export default RotatingText;
