/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { Box, useTheme, Typography, LinearProgress } from '@mui/material';
import axios from 'axios';
import { urls } from '../../../config'; // API URLのファイル
import InstagramGridStyles from './InstagramGridSectionStyles';
import Slider from "react-slick"; // react-slickをインポート
import { H2Text } from '../../ui';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        left: '30px',
        color: 'white',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
        '.slick-prev': {
          width: '50px',
          height: '50px',
        }
      }}
      onClick={onClick}
    >
      ←
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        right: '30px',
        color: 'white',
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        cursor: 'pointer',
        '.slick-next': {
          width: '30px',
          height: '30px',
        }
      }}
      onClick={onClick}
    >
      →
    </div>
  );
};

const InstagramGridSection = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true); // ローディング状態
  const theme = useTheme();
  const classes = InstagramGridStyles(theme);

  // iOS またはモバイルデバイスを判定
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isMobile = /Mobi|Android/i.test(navigator.userAgent) || isIOS;

  useEffect(() => {
    const fetchInstagramPosts = async () => {
        try {
          const response = await axios.get(`${urls.api}/web/instagram-posts/`);
          setPosts(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching Instagram posts', error);
          setLoading(false);
      }
    };

    fetchInstagramPosts();
  }, []);

  if (loading) {
    return (
      <Box css={classes.container}>
        <Box css={classes.wrapper}>
          <Box>
            <H2Text size={theme.typography.titleLarge} css={classes.title}>
              最近の活動
            </H2Text>
          </Box>
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Loading
            </Typography>
            <LinearProgress sx={{ width: '80%' }} />
          </Box>
        </Box>
      </Box>
    )
  }

  // 動画の再生/停止を制御するためのクリックハンドラ
  const handleVideoClick = (e) => {
    const video = e.target;
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };

  // react-slick の設定
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    arrows: true,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // 画面幅1024px以下の場合は2スライド表示
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600, // 画面幅600px以下の場合は1スライド表示
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Box css={classes.container}>
      <Box css={classes.wrapper}>
        <Box>
          <H2Text size={theme.typography.titleLarge} css={classes.title}>
            最近の活動
          </H2Text>
        </Box>
        <Slider {...settings}>
          {posts.map((post) =>
            post.media_url ? (
              <Box key={post.id} css={classes.cardWrapper}>
                <Box css={classes.card}>
                  <a href={post.permalink} target="_blank" rel="noopener noreferrer">
                    {isMobile ? (
                      <img src={post.media_url} alt={post.caption} css={classes.media} loading="lazy" />
                    ) : post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM' ? (
                      <img src={post.media_url} alt={post.caption} css={classes.media} loading="lazy" />
                    ) : post.media_type === 'VIDEO' ? (
                      <video
                        onClick={handleVideoClick}
                        css={classes.media}
                        muted
                        preload="metadata"
                      >
                        <source src={post.media_url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <p>Unsupported media type</p>
                    )}
                    <Box css={classes.overlay}>
                      <span>もっと見る</span>
                    </Box>
                  </a>
                </Box>
              </Box>
            ) : null
          )}
        </Slider>
      </Box>
    </Box>
  );
};

export default InstagramGridSection;
