/** @jsxImportSource @emotion/react */
import React from 'react';
import { Link } from '@mui/material';
import LogoStyles from './LogoStyles';

const Logo = ({ href, src, alt, theme }) => {
  const classes = LogoStyles(theme);

  return (
    <Link href={href} css={classes.logo} aria-label={alt} rel="noopener noreferrer">
      <img src={src} alt={alt} css={classes.logoImage} />
    </Link>
  );
};

export default Logo;
