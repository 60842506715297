/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Typography, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import useContactForm from './UseContactForm';
import ContactFormStyles from './ContactFormStyles';
import { BodyText, CustomButton, CustomTextField, CustomRadioGroup } from '../../../../../../components/ui';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { urls } from '../../../../../../config';

const ContactForm = () => {
  const theme = useTheme();
  const classes = ContactFormStyles(theme);
  const {
    formData,
    errors,
    status,
    open,
    submitting,
    handleChange,
    handleDialogOpen,
    handleDialogClose,
    handleDialogSubmit,
  } = useContactForm();

  return (
    <>
    <form onSubmit={(e) => e.preventDefault()} css={classes.form}>
        <CustomTextField
            label="*お名前"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={errors.name}
            helperText={errors.name && "お名前を入力してください"}
        />
        <CustomTextField
            label="*お名前（ふりがな）"
            name="furiname"
            value={formData.furiname}
            onChange={handleChange}
            error={errors.furiname}
            helperText={errors.furiname && "お名前（ふりがな）を入力してください"}
        />
        <CustomTextField
            label="*電話番号"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            error={errors.phone}
            helperText={errors.phone && "電話番号を入力してください"}
        />
        <CustomTextField
            label="*メールアドレス"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
            helperText={errors.email && "有効なメールアドレスを入力してください"}
        />
        <CustomTextField
            label="*メールアドレス（確認用）"
            name="confirmEmail"
            value={formData.confirmEmail}
            onChange={handleChange}
            error={errors.confirmEmail}
            helperText={errors.confirmEmail && "メールアドレスが一致しません"}
        />
        <CustomRadioGroup
          label="*優先連絡手段"
          name="preContact"
          value={formData.preContact}
          onChange={handleChange}
          options={[
            { value: '電話', label: '電話' },
            { value: 'メール', label: 'メール' },
          ]}
          error={errors.preContact}
          helperText={errors.preContact && "優先連絡手段を選択してください"}
        />
        <CustomTextField
            label="*お問い合わせ内容"
            name="message"
            value={formData.message}
            onChange={handleChange}
            multiline
            rows={4}
            error={errors.message}
            helperText={errors.message && "お問い合わせ内容を入力してください"}
        />

        {errors.agree && (
            <BodyText size={theme.typography.smallText} css={classes.errorPrivacyPolicy} color="error">
                プライバシーポリシーに同意してください。
            </BodyText>
        )}
        <FormControlLabel
            control={
                <Checkbox
                    checked={formData.agree}
                    onChange={handleChange}
                    name="agree"
                    color="primary"
                />
            }
            label={
                <Typography
                    component="a"
                    href={urls.privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'blue', textDecoration: 'none' }}
                >
                    プライバシーポリシーに同意する
                </Typography>
            }
            css={classes.privacyPolicy}
        />

        <CustomButton
            size={classes.customButtonSize}
            width={classes.customButtonWidth}
            minWidth={classes.customButtonMinWidth}
            variant="button"
            onClick={handleDialogOpen}
            disabled={submitting}
        >入力内容の確認</CustomButton>

        <ConfirmDialog
            formData={formData}
            open={open}
            handleDialogClose={handleDialogClose}
            handleDialogSubmit={handleDialogSubmit}
        />
    </form>
   
    {submitting && <CircularProgress />}  {/* 送信中のローディングを表示 */}

    {status && (
    <Typography
        variant="body1"
        css={classes.statusMessage(status.startsWith('Error'))}
    >
        {status}
    </Typography>
    )}
    </>
  );
};

export default ContactForm;
