import { useState } from 'react';

const useMenu = () => {
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({});

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleSubMenuToggle = (index) => {
    setOpenSubMenu((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return {
    open,
    openSubMenu,
    handleDrawerToggle,
    handleSubMenuToggle,
  };
};

export default useMenu;
