
/** @jsxImportSource @emotion/react */
import React from 'react';
import {
    SEO, Menu, Footer, TitleSection,
    BusinessOverviewSection, AchievementsSection,
    EventsSection, ContactSection,
} from '../../components/common';
import { Background, BusinessSection1 } from './components';
import { achievmentData, seoConfig } from '../../config';

const RukhKids = () => {
    return (
        <>
        <SEO 
            title={seoConfig.rukhKids.title}
            description={seoConfig.rukhKids.description}
            keywords={seoConfig.rukhKids.keywords}
        />
        <header>
            <Menu />
        </header>
        <main>
            <section>
                <TitleSection englishTitle="About RUKH KID`S" japaneseTitle="RUKH KID`Sについて" />
            </section>
            <section>
                <BusinessOverviewSection
                    id="rukh-kids"
                    title="RUKH KID`S"
                    main="楽しみながら未来を築く子どもたちの成長ステージ"  
                    sub="子どもたちが楽しみながら自己表現力と協調性を育み、自信を持って未来に向かって歩んでいける環境を提供します。"
                    body="RUKH KID'Sでは、子どもたちが楽しみながら自分のペースで成長できるよう、温かくサポートします。ダンスや運動を通じて、自己表現力、協調性、そして心身の健康を育むことを目指しています。私たちの経験豊富なインストラクターが子どもたち一人ひとりに寄り添い、未来に向けて自信を持って進めるよう、愛情を持って指導しています。"
                    src="/images/800Business/802OverviewSectionImage.png" 
                    alt="overviewSectionImage" 
                />
            </section>
            <Background>
                <section>
                    <BusinessSection1 />
                    <AchievementsSection achievmentData={achievmentData.business1} />
                </section>
                <section>
                    <EventsSection />
                </section>
                <secttion>
                    <ContactSection />
                </secttion>
            </Background>
        </main>
        <footer>
            <Footer />
        </footer>
        </>
    );
};

export default RukhKids;
