import { css } from '@emotion/react';

const ChallengesSectionStyles = (theme) => ({
    container: css({
        marginTop: '50px',
        width: '100%',
        padding: '50px 40px',
        backgroundColor: '#f5f5f5',
    }),
    wrapper: css({
        width: '100%',
        maxWidth: theme.width.max,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    contents: css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '20px',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
        }, 
    }),
    title: css({
        fontWeight: 'bold',
        marginBottom: '50px',
        [theme.breakpoints.down('md')]: {
            fontSize: '24px',
        },
    }),
    description: css({
        marginBottom: '50px',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        },
    }),
});

export default ChallengesSectionStyles;
