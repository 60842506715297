const achievmentData = {
    business1: [
        {
            src: '/images/800Business/806Business1_1.png',
            title: '地元のお祭り',
            alt: '地元のお祭りの画像'
        },
        {
            src: '/images/800Business/806Business1_2.png',
            title: 'ダンス発表会',
            alt: 'ダンス発表会の画像'
        },
        {
            src: '/images/800Business/806Business1_3.png',
            title: 'ダンスレッスン',
            alt: 'ダンスレッスンの画像'
        }
    ],
    business2: [
        {
            src: '/images/800Business/806Business2_1.png',
            title: 'ランニング教室',
            alt: 'ランニング教室の画像'
        },
        {
            src: '/images/800Business/806Business2_2.png',
            title: 'はつらつのつどい',
            alt: 'はつらつのつどいというイベントの画像'
        },
        {
            src: '/images/800Business/806Business2_3.png',
            title: '高齢者サロン',
            alt: '高齢者サロンの画像'
        }
    ]
}

export default achievmentData;
