/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { H2Text, H3Text } from '../../../../components/ui';
import CorporatePhilosophySectionStyle from './CorporatePhilosophySectionStyle';

const CorporatePhilosophySection = () => {
    const theme = useTheme();
    const classes = CorporatePhilosophySectionStyle(theme);
    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <H2Text size={theme.typography.display} css={classes.h2Text}>
                    私たちは、ダンスと運動を通じて<br />心と体の健康を育みます。
                </H2Text>
                <H3Text size={theme.typography.titleSmall} css={classes.text}>
                    地域コミュニティーを目指し、誰もが安心して参加できる環境を整え、健康と笑顔を届けます。
                </H3Text>
            </Box>
        </Box>
    );
};

export default CorporatePhilosophySection;
