import { css } from '@emotion/react';

const IntroductionSectionStyles = (theme) => ({
  container: css({
    width: '100%',
    padding: '50px 0',
    [theme.breakpoints.down('md')]: {
      padding: '40px 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0',
    },
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: theme.width.max,
    margin: '0 auto',
  }),
  textContainer: css({
    width: '80%',
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '60px 30px',
    borderRadius: '40px',
    [theme.breakpoints.down('md')]: {
      padding: '70px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '50px 15px',
    },
  }),
  title: css({
    fontWeight: 'bold',
    marginBottom: '20px',
  }),
  message: css({
    fontWeight: 'bold',
  }),
});

export default IntroductionSectionStyles;
