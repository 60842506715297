/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box } from '@mui/material';
import ContactFormSectionStyles from './ContactFormSectionStyles';
import { BodyText } from '../../../../components/ui';
import ContactForm from './components/ContactForm/ContactForm';

const ContactFormSection = () => {
  const theme = useTheme();
  const classes = ContactFormSectionStyles(theme);

  return (
    <Box css={classes.container}>
      <Box css={classes.wrapper}>
        <BodyText size={theme.typography.body} css={classes.title}>
          お問い合わせを入力してください。
          <br />
          *は必須入力です。必ず入力してください。
        </BodyText>
        <ContactForm />
      </Box>
    </Box>
  );
};

export default ContactFormSection;
