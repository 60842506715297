const featuresData = [
    {
        src: '/images/400ForParents/403FeatureImage1.png',
        title: '送迎不要で便利',
        message: '幼稚園や保育園の延長として、施設内でレッスンが受けられるため、送迎の手間が省ける',
        reverse: false,
    },
    {
        src: '/images/400ForParents/403FeatureImage2.png',
        title: '専門ダンスへのステップアップ',
        message: 'ダンスの基礎を学びながら、将来的にヒップホップ、バレエ、ジャスなど専門的なダンス教室に進むためのステップになる',
        reverse: true,
    },
    {
        src: '/images/400ForParents/403FeatureImage3.png',
        title: '心身ともに成長',
        message: 'ダンスを通じて、子供の体力や体の成長だけでなく、心の成長も同時に育む',
        reverse: false,
    },
    {
        src: '/images/400ForParents/403FeatureImage4.png',
        title: 'コミュニケーション力向上',
        message: 'ダンスを通じて、思いやりを持ったコミュニケーション能力とチームワークを育む',
        reverse: true,
    },
    {
        src: '/images/400ForParents/403FeatureImage5.png',
        title: '個々の意思を尊重',
        message: '子供の意思を尊重し、ダンスが苦手な場合にも、その気持ちに寄り添いながら、一緒に解決策を考えて前向きにサポートします。',
        reverse: false,
    },
    {
        src: '/images/400ForParents/403FeatureImage6.png',
        title: '自信を育てる環境',
        message: '安心できる慣れた環境でダンスを始めやすくし、子供たちの自信を段階的に育てていく。',
        reverse: true,
    },
]

export default featuresData;
