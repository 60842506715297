/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BodyText, H2Text, H3Text } from '../../../../components/ui';
import SolutionSectionStyles from './SolutionSectionStyles';
import { solutions } from '../../../../config';

const SolutionSection = () => {
    const theme = useTheme();
    const classes = SolutionSectionStyles(theme);

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <H2Text size={theme.typography.title} css={classes.title}>Rukhならすべて解決!</H2Text>
                <img src="/logo192.png" alt="logo" css={classes.logo}/>
                {solutions.map((solution, index) => (
                    <Box key={index} css={classes.content}>
                        <Box css={classes.solutionImageContainer}>
                            <img src={solution.imgSrc} alt={solution.title} css={classes.solutionImage} />
                        </Box>
                        <Box css={classes.textContainer}>
                            <BodyText size={theme.typography.titleSmall} css={classes.point}>{solution.point}</BodyText>
                            <H3Text size={theme.typography.title} css={classes.description}>{solution.title}</H3Text>
                            <BodyText size={theme.typography.body} css={classes.description}>{solution.description}</BodyText>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default SolutionSection;
