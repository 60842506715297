import { css } from '@emotion/react';

const TitleWithButtonStyles = (theme) => ({
    titleBox: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '40px',
        width: '100%',
        maxWidth: theme.width.max,
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '10px',
        },
    }),
    title: css({
        fontWeight: 'bold',
    }),
    iconBox: css({
        display: 'flex',
        alignItems: 'center',
    }),
    button: css({
        color: theme.palette.primary.main,
        backgroundColor: '#fff',
        padding: '10px',
        margin: '0 10px',
        boxShadow: theme.shadows[1],
        [theme.breakpoints.down('md')]: {
            padding: '5px',
            top: '60%',
            position: 'absolute',
            transform: 'translateY(-50%)',
            zIndex: '10',
        },
    }),
    leftButton: css({
        left: '0',
    }),
    rightButton: css({
        right: '0',
    }),
});

export default TitleWithButtonStyles;
