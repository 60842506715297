/** @jsxImportSource @emotion/react */
import React from 'react';
import { Drawer, List, ListItem, IconButton, Collapse, Link, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { ExpandLess, ExpandMore, ArrowOutward } from '@mui/icons-material';
import { H3Text, BodyText, H2Text } from '../../../../ui/CustomText/CustomText';
import drawerStyles from './CustomDrawerStyles';
import navItems from '../../../../../config/navItems';
import Logo from '../Logo/Logo';

const CustomDrawer = ({ open, handleDrawerToggle, openSubMenu, handleSubMenuToggle }) => {
  const theme = useTheme();
  const classes = drawerStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleDrawerToggle}
      css={classes.drawer}
      BackdropProps={{ invisible: true }}
    >
      <H2Text size={theme.typography.invisible}>ナビゲーションメニュー</H2Text>
      {isMobile && (
        <ListItem css={classes.drawerHeader}>
          <Logo href="/" src="/logo.png" alt="Logo" theme={theme} />
          <IconButton
            edge="end"
            sx={{ color: theme.palette.text.secondary }}
            aria-label="close"
            onClick={handleDrawerToggle}
            css={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </ListItem>
      )}
      <nav>
        <List css={classes.drawerList}>
          {navItems.map((item, index) => (
            <div key={index}>
              <ListItem button css={classes.drawerItem}>
                <Link
                  href={item.url}
                  css={classes.MenuItemLinkText}
                  aria-label={item.title}
                  rel="noopener noreferrer"
                >
                  <H3Text size={theme.typography.bodySmall}>{item.title}</H3Text>
                  <ArrowOutward css={classes.colorPrime} fontSize="small" />
                </Link>
                {item.subItems ? (
                  openSubMenu[index] ? (
                    <ExpandLess
                      onClick={() => handleSubMenuToggle(index)}
                      sx={{ color: theme.palette.text.secondary }}
                    />
                  ) : (
                    <ExpandMore
                      onClick={() => handleSubMenuToggle(index)}
                      sx={{ color: theme.palette.text.secondary }}
                    />
                  )
                ) : null}
              </ListItem>
              {item.subItems && (
                <Collapse in={openSubMenu[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem, subIndex) => (
                      <ListItem
                        button
                        key={subIndex}
                        component="a"
                        href={subItem.url}
                        css={classes.subMenuItem}
                        aria-label={subItem.text}
                        rel="noopener noreferrer"
                      >
                        <BodyText size={theme.typography.bodySmall}>{subItem.text}</BodyText>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </nav>
    </Drawer>
  );
};

export default CustomDrawer;
