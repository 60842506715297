/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { H2Text } from '../../ui';
import AchievmentsSectionStyles from './AchievmentsSectionStyles';
import { AchievmentContent } from './components';

const AchievmentsSection = ({ achievmentData }) => {
    const theme = useTheme();
    const classes = AchievmentsSectionStyles(theme);

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <H2Text size={theme.typography.title} css={classes.title}>活動実績</H2Text>
                <Box css={classes.contents}>
                    {achievmentData.map((achievment, index) => (
                    <AchievmentContent
                        key={index}
                        src={achievment.src}
                        alt={achievment.alt}
                        title={achievment.title}
                    />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default AchievmentsSection;
