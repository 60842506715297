import { css } from '@emotion/react';

const CarouselStyles = (theme) => ({
    carousel: css({
        overflow: 'hidden',
        position: 'relative',
    }),
    slider: (style) => css`
        width: 100%;
        white-space: nowrap;
        transform: ${style.transform};
        transition: ${style.transition};
    `,
    listItem: css({
        display: 'inline-block',
        padding: '10px',
        width: '30%',
        transform: 'translate3d(17%, 0, 0)',
        [theme.breakpoints.down('md')]: {
            width: '42%',
            transform: 'translate3d(19%, 0, 0)',
        },
        [theme.breakpoints.down('sm')]: {
            width: '88%',
            transform: 'translate3d(7%, 0, 0)',
        },
    }),
});

export default CarouselStyles;
  