const challengesData = [
    {
        src: '/images/800Business/803ChallengesSectionImage1.png',
        title: '子供隊の運動不足が心配',
        message: '近頃の子供達は、スマホやゲームに夢中で、運動不足が心配です。そんな子供達に、楽しく運動をさせる方法はないでしょうか？'
    },
    {
        src: '/images/800Business/803ChallengesSectionImage2.png',
        title: '子供に習い事をさせたいけど時間がない',
        message: '仕事や家事が忙しく、子供に習い事をさせる時間がないとお悩みの保護者の方も多いのではないでしょうか？'
    },
    {
        src: 'images/800Business/803ChallengesSectionImage3.png',
        title: '福利厚生を充実させたいけど、何をすればいいかわからない',
        message: '福利厚生を充実させたいと考えているが、何をすればいいかわからないとお悩みの幼稚園の関係者の方も多いのではないでしょうか？'
    },
];

export default challengesData;