/** @jsxImportSource @emotion/react */
import React from 'react';
import { AppBar, Toolbar, IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import MenuStyles from './MenuStyles';
import useMenu from './UseMenu';
import { NavItem, Logo, CustomDrawer } from './components';
import navItems from '../../../config/navItems';
import { H2Text } from '../../ui';
import { useScrollToTop } from '../../../utils';

const Menu = () => {
  const theme = useTheme();
  const classes = MenuStyles(theme);
  const isMobile = useMediaQuery('(max-width:928px)');
  const { open, openSubMenu, handleDrawerToggle, handleSubMenuToggle } = useMenu();

  useScrollToTop();

  return (
    <>
      <AppBar position="sticky" css={classes.appbar}>
        <Toolbar css={classes.toolbar}>
          <Logo href="/" src="/logo.png" alt="Logo" theme={theme} />
          {!isMobile && (<H2Text size={theme.typography.invisible}>ナビゲーションメニュー</H2Text>)}
          {isMobile ? (
            <IconButton edge="end" css={classes.iconButton} aria-label="menu" onClick={handleDrawerToggle}>
              <MenuIcon />
            </IconButton>
          ) : (
            <nav css={classes.nav}>
              {navItems.map((item, index) => (
                <NavItem key={index} title={item.title} url={item.url} subItems={item.subItems} />
              ))}
            </nav>
          )}
        </Toolbar>
      </AppBar>
      <CustomDrawer
        open={open}
        handleDrawerToggle={handleDrawerToggle}
        openSubMenu={openSubMenu}
        handleSubMenuToggle={handleSubMenuToggle}
      />
    </>
  );
};

export default Menu;
