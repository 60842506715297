/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import ContentStyles from './ContentStyles';
import { H2Text, CustomButton, BodyText } from '../../../../../../components/ui';
import { useNavigateToUrl } from '../../../../../../utils';

const Content = ({ title, message, reverse, url, imagePath }) => {
    const theme = useTheme();
    const classes = ContentStyles(theme, reverse, imagePath);
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToUrl = useNavigateToUrl();

    return (
        <Box css={classes.container}>
            <Box css={classes.square1}>
                <Box>
                    <H2Text
                        size={theme.typography.titleLarge}
                        css={classes.title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    ></H2Text>
                    <BodyText
                        size={theme.typography.bodyLarge}
                        css={classes.message}
                        dangerouslySetInnerHTML={{ __html: message }}
                    ></BodyText>
                </Box>
                <Box>
                    <CustomButton
                        size="medium"
                        minWidth={classes.customButtonMinWidth}
                        width={isMd ? classes.customButtonMdWidth : classes.customButtonWidth }
                        variant="small"
                        onClick={() => navigateToUrl(url)}
                    >
                        詳しく見る
                    </CustomButton>
                </Box>
            </Box>
            <Box css={classes.square2}>
                {imagePath && <img src={imagePath} alt={title} css={classes.image} />}
            </Box>
        </Box>
    );
};

export default Content;
