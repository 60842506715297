import { css } from '@emotion/react';

const MissionSectionStyle = (theme) => ({
    container: css({
        width: '100%',
    }),
    wrapper: css({
        maxWidth: theme.width.max,
        margin: '0 auto',
        borderTop: `1px solid ${theme.palette.border.sub}`,
    }),
});

export default MissionSectionStyle;
