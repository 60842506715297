const testimonials = [
    {
        imageSrc: '/images/100TopPage/105Testimonial1.png',
        textLine1: '',
        textLine2: 'Tちゃんの保護者様より',
        comment: '最初は私も子供も不安でしたが、ダンスの時間がどんどん楽しくなってきて、次のレッスンが待ち遠しくなりました。発表会では笑顔も見れてうれしかったです。',
    },
    {
        imageSrc: '/images/100TopPage/105Testimonial2.png',
        textLine1: '',
        textLine2: 'S君の保護者様より',
        comment: 'メンバーと助け合って、頑張っている姿を見て成長を感じました。',
    },
    {
        imageSrc: '/images/100TopPage/105Testimonial3.png',
        textLine1: '',
        textLine2: 'Mちゃんの保護者様より',
        comment: '帰ってきて、自分から自主練するようになりました。苦手が多いので一時はやる気を失っていましたが、自分から前向きに取り組む姿勢が親としてはとても嬉しく思います。自分の好きを伸ばしていってほしいと思います。',
    },
    {
        imageSrc: '/images/100TopPage/105Testimonial4.png',
        textLine1: '',
        textLine2: 'Kちゃんの保護者様より',
        comment: 'ダンスの楽しさ以外に達成感や自己肯定感を感じることができたり、協調性を学ぶことがたくさんあり、とても感謝しています',
    },
    {
        imageSrc: '/images/100TopPage/105Testimonial6.png',
        textLine1: '',
        textLine2: 'Yちゃんのお母様より',
        comment: '共働きで、幼稚園の延長保育と好きな習い事がそのままできると言うことが大変助かります。ありがとうございます。',
    },
];

export default testimonials;
