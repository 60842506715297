/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box } from '@mui/material';
import TitleSectionStyles from './TitleSectionStyles';
import { BodyText, H1Text } from '../../ui';

const ContactSection = ({ englishTitle, japaneseTitle, remarks = null }) => {
    const theme = useTheme();
    const classes = TitleSectionStyles(theme);

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <BodyText size={theme.typography.bodyLarge} css={classes.englishTitle}>
                    {englishTitle}
                </BodyText>
                <H1Text size={theme.typography.titleSmall} css={classes.japaneseTitle}>
                    {japaneseTitle}
                </H1Text>
                {remarks && (
                    <BodyText size={theme.typography.bodyMedium} css={classes.remarks}>
                        {remarks}
                    </BodyText>
                )}
            </Box>
        </Box>
    );
};

export default ContactSection;
