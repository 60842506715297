import { css, keyframes } from '@emotion/react';

const rotateAndChange = keyframes`
  0% {
    transform: rotateX(-90deg) translateZ(-20px) translateY(-20px);
  }
  20% {
    transform: rotateX(0deg) translateZ(0px) translateY(0px);
  }
  80% {
    transform: rotateX(0deg) translateZ(0px) translateY(0px);
  }
  100% {
    transform: rotateX(90deg) translateZ(-20px) translateY(-20px);
  }
`;

const useRotatingText = (theme, interval) => ({
  rotatingText: css({
    display: 'inline-block',
    animation: `${rotateAndChange} ${interval}ms infinite`,
    transformOrigin: 'center',
  }),
})

export default useRotatingText;
