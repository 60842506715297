/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box } from '@mui/material';
import IntroductionSectionStyles from './IntroductionSectionStyles';
import { Carousel } from './components';
import { H1Text, BodyText } from '../../../../components/ui';
import { introductionImages } from '../../../../config';

const IntroductionSection = () => {
  const theme = useTheme();
  const classes = IntroductionSectionStyles(theme);
  
  return (
    <Box css={classes.container}>
      <Box css={classes.wrapper}>
        <Box css={classes.textContainer}>
          <H1Text size={theme.typography.titleLarge} css={classes.title}>
            RUKH KID`S
          </H1Text>
          <BodyText size={theme.typography.bodyLarge} css={classes.message}>
            私たちは幼稚園児向けに楽しくて健康的なダンスレッスンを提供しています。
            <br />
            ダンスのメリットとRUKH KID`Sの特徴について説明します。
          </BodyText>
        </Box>
        <Carousel images={introductionImages} />
      </Box>
    </Box>
  );
};

export default IntroductionSection;
