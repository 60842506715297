import { css } from '@emotion/react';

const TestimonialStyles = (theme) => ({
    container: css({
        display: 'flex',
        flexDirection: 'column',
        height: '200px',
        padding: theme.spacing(2),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '30px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        textAlign: 'left',
        overflow: 'hidden',
    }),
    imageTextContainer: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
    }),
    image: css({
        width: '50px',
        height: '60px',
        marginRight: theme.spacing(2),
    }),
    textContainer: css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        overflowY: 'auto', // 縦スクロールを有効にする
    }),
    textLine: css({
        color: theme.palette.primary.main,
        whiteSpace: 'nowrap', // ここは 'wrap' のままでもOK
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }),
    comment: css({
        marginTop: theme.spacing(1),
        whiteSpace: 'wrap',
        overflow: 'auto', // スクロールを有効にする
        maxHeight: '100px', // コメントのスクロール範囲
    }),
});

export default TestimonialStyles;
