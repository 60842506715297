import { css } from '@emotion/react';

const CustomTextFieldStyles = (theme) => ({
    formControl: css({
        display: 'flex',
        width: '100%',
        marginBottom: '16px',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    }),
    label: css({
        flexBasis: '30%',
        textAlign: 'left',
        marginRight: 16,
        fontWeight: 'bold',
    }),
    textField: css({
        flexBasis: '70%',
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
        },
        '& textarea': {
            resize: 'vertical',
        },
    }),
});

export default CustomTextFieldStyles;
