
import { css } from '@emotion/react';

const GreetingSectionStyles = (theme) => ({
    container: css({
        marginTop: '100px',
        width: '100%',
    }),
    wrapper: css({
        position: 'relative',
        width: '80%',
        maxWidth: theme.width.max,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
    }),
    title: css({
        paddingBottom: '20px',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        marginBottom: '50px',
        fontWeight: 'bold',
    }),
    contents: css({
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        }
    }),
    imageContainer: css({
        flex: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '40px',
    }),
    greetingImage: css({
        maxWidth: '100%',
        height: 'auto',
        borderRadius: 'inherit',
        [theme.breakpoints.down('md')]: {
            maxWidth: '50%',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '70%',
        },
    }),
    messageContainer: css({
        flex: 6,
        padding: '20px 20px 20px 50px', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            padding: '20px',
        },
    }),
    greetingMessage: css({
        lineHeight: '60px',
    }),
    name: css({
        textAlign: 'right',
    }),
});

export default GreetingSectionStyles;
