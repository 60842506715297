/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import FeatureStyles from './FeatureStyles';
import { H3Text, BodyText } from '../../../../../../components/ui';

const Feature = ({ image, title, message, reverse }) => {
    const theme = useTheme();
    const classes = FeatureStyles(theme, reverse);

    return (
        <Box css={classes.container}>
            <Box css={classes.imageContainer}>
                <img src={image} alt={title} css={classes.image} />
            </Box>
            <Box css={classes.contentContainer}>
                <H3Text size={theme.typography.titleSmall} css={classes.title}>{title}</H3Text>
                <BodyText size={theme.typography.bodyLarge} css={classes.message}>{message}</BodyText>
            </Box>
        </Box>
    )
};

export default Feature;
