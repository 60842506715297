import { css } from '@emotion/react';

const sizeStyles = {
  small: { height: '32px', borderRadius: '8px' },
  medium: { height: '40px', borderRadius: '10px' },
  large: { height: '48px', borderRadius: '12px' },
};

const useCustomButtonStyles = (theme, size, width, minWidth, colorVariant) => {
  const { height, borderRadius } = sizeStyles[size] || sizeStyles.medium;

  const isWhiteBackground = colorVariant === 'whiteBackground';
  const isGrayBackground = colorVariant === 'grayBackground';

  const backgroundColor = isWhiteBackground
    ? theme.palette.white.main
    : isGrayBackground
    ? '#bdbdbd'
    : theme.palette.primary.main;

  const textColor = isWhiteBackground
    ? theme.palette.text.primary
    : theme.palette.common.white;

  return {
    button: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: width,
      minWidth: minWidth,
      height: height,
      position: 'relative',
      border: 'none',
      cursor: 'pointer',
      overflow: 'hidden',
      padding: '0 2px',
      '&:hover .leftBox': {
        width: 'calc(100% - 80px)',
      },
      '&:hover .rightBox': {
        width: '60px',
      },
      '&:active': {
        backgroundColor: isWhiteBackground || isGrayBackground
          ? `rgba(${parseInt(theme.palette.text.primary.slice(1, 3), 16)}, 
                    ${parseInt(theme.palette.text.primary.slice(3, 5), 16)}, 
                    ${parseInt(theme.palette.text.primary.slice(5, 7), 16)}, 0.7)`
          : `rgba(${parseInt(theme.palette.primary.main.slice(1, 3), 16)}, 
                    ${parseInt(theme.palette.primary.main.slice(3, 5), 16)}, 
                    ${parseInt(theme.palette.primary.main.slice(5, 7), 16)}, 0.7)`,
      },
    }),
    leftBox: css({
      display: 'flex',
      width: 'calc(100% - 60px)',
      height: '100%',
      backgroundColor: backgroundColor,
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingLeft: '10px',
      borderRadius: `${borderRadius} 0 ${borderRadius} ${borderRadius}`,
      transition: 'width 0.3s',
      color: textColor,
    }),
    rightBox: css({
      display: 'flex',
      width: '40px',
      height: '100%',
      backgroundColor: backgroundColor,
      alignItems: 'center',
      justifyContent: 'end',
      paddingRight: '5px',
      borderRadius: `${borderRadius} ${borderRadius} ${borderRadius} 0`,
      transition: 'width 0.3s',
      color: textColor,
    }),
    leftTrapezoid: css({
      backgroundColor: backgroundColor,
      width: '30px',
      height: '100%',
      transform: 'skewX(-20deg) translateX(8px)',
      borderRadius: '20%',
    }),
    rightTrapezoid: css({
      backgroundColor: backgroundColor,
      width: '30px',
      height: '100%',
      borderRadius: '20%',
      transform: 'skewX(-20deg) translateX(-5px)',
    }),
    alignCenterBox: css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    }),
  };
};

export default useCustomButtonStyles;
