const introductionBenefitsData = [

    {
        src: '/images/400ForParents/403BenefitImage1.png',
        title: '体力の向上と健康増進',
        message: '現在の子供たちの体力低下が問題となっており、ダンスを通じて楽しく運動し体力を向上させることが期待されています'
    },
    {
        src: '/images/400ForParents/403BenefitImage2.png',
        title: '表現力とコミュニケーション能力の育成',
        message: 'ダンスは身体表現を通じて自分の表現する力を養います。また、グループでのダンス活動を通じて協力やコミュニケーション能力を育むことができます。'
    },
    {
        src: '/images/400ForParents/403BenefitImage3.png',
        title: '文化的な多様性の理解',
        message: 'ダンスには多様なジャンルがあり、異なる文化や伝統を学ぶ機会になります。これにより子供たちが文化の多様性を理解し、尊重する姿勢を育てることができます。。'
    },
    {
        src: '/images/400ForParents/403BenefitImage4.png',
        title: '心身のバランスの取れた成長',
        message: 'ダンスは身体だけでなく、心の健康にも影響を与えるとされています。リズムに乗って動くことでストレスを軽減し、自己肯定感を高めることが期待されています。'
    },
];

export default introductionBenefitsData;
