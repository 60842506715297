import { css, keyframes } from '@emotion/react';

// アニメーションの定義
const slide = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

const CarouselStyles = (theme) => ({
    carousel: css({
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
        position: 'relative',
        marginTop: '-30px',
        '& .inner-carousel': {
        display: 'flex',
        width: '300%',
        animation: `${slide} 50s linear infinite`,
        height: '400px',
        [theme.breakpoints.down('md')]: {
            height: '300px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '200px',
        },
        },
    }),
    imageBox: css({
        flexShrink: 0,
        height: '60%',
        width: 'auto',
        overflow: 'hidden',
        padding: '0 10px',
        display: 'flex', 
        justifyContent: 'center',
        '& img': {
        height: '100%',
        width: 'auto',
        borderRadius: '20px',
        },
        '&:nth-of-type(odd)': {
        alignSelf: 'flex-start',
        },
        '&:nth-of-type(even)': {
        alignSelf: 'flex-end',
        },
        [theme.breakpoints.down('md')]: {
            padding: '0 20px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px',
        },
    }),
});

export default CarouselStyles;
