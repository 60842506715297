/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { MessageSection } from '../../../../components/common';
import MissionSectionStyle from './MissionSectionStyle';

const MissionSection = () => {
    const theme = useTheme();
    const classes = MissionSectionStyle(theme);

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <MessageSection
                    title="使命" 
                    message="私たちは、ダンスを通じてすべての世代の心と体の健康をサポートします。また、子供たちが楽しみながら成長し、その経験が将来に役立つ教育を提供します。" 
                    id="mission"
                />
            </Box>
        </Box>
    );
};

export default MissionSection;
