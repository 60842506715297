/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import CustomDropDownListStyles from './CustomDropDownListStyles';
import { BodyText } from '../CustomText/CustomText';

const CustomDropDownList = ({ label, name, value, onChange, options = [], error, helperText }) => {
  const theme = useTheme();
  const classes = CustomDropDownListStyles(theme);

  return (
    <Box css={classes.formControl}>
      <BodyText size={theme.typography.body} css={classes.label}>
        {label}
      </BodyText>
      <FormControl fullWidth error={Boolean(error)} css={classes.ddl}>
        <Select name={name} value={value} onChange={onChange} css={classes.selectField}>
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default CustomDropDownList;
