import { css } from '@emotion/react';

const AboutUsSectionStyles = (theme, isMd, isSm) => ({
    container: css({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '100px',
    }),
    wrapper: css({
        position: 'relative',
        width: '100%',
        maxWidth: theme.width.max,
        display: 'flex',
        aspectRatio: '10 / 6',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
    }),
    backgroundImage: css({
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        zIndex: -1,
        [theme.breakpoints.down('md')]: {
            content: `url('/images/100TopPage/102AboutUsSectionBackGroundMedium.png')`,
        },
        [theme.breakpoints.down('sm')]: {
            content: `url('/images/100TopPage/102AboutUsSectionBackGroundSmall.png')`,
        },
    }),
    circle: css({
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        width: '50%',
        aspectRatio: '1 / 1',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '40px 20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        [theme.breakpoints.down('md')]: {
            width: '75%',
            padding: '40px 70px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '95%',
            borderRadius: '30%',
            padding: '40px 20px',
        }

    }),
    logo: css({
        width: '20%',
        aspectRatio: '1 / 1',
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            width: '25%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '30%',
            marginBottom: '10px',
        }
    }),
    title: css({
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
        }
    }),
    baseBodyTextStyle: css({
        marginBottom: '40px',
        fontWeight: '600',
        [theme.breakpoints.down('md')]: {
            marginBottom: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
        },
    }),
    customButtonSize: isMd ? 'small' : 'medium',
    customButtonWidth: isMd ? '200px' : '250px',
    
});

export default AboutUsSectionStyles;
