/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import TestimonialStyles from './TestimonialStyles';
import { BodyText } from '../../../../../../components/ui';

const Testimonial = ({ imageSrc, textLine1, textLine2, comment }) => {
    const theme = useTheme();
    const classes = TestimonialStyles(theme);

    return (
        <Box css={classes.container}>
            <Box css={classes.imageTextContainer}>
                <img src={imageSrc} alt="gardianImage" css={classes.image} />
                <Box css={classes.textContainer}>
                    <BodyText size={theme.typography.body} css={classes.textLine}>{textLine1}</BodyText>
                    <BodyText size={theme.typography.body} css={classes.textLine}>{textLine2}</BodyText>
                </Box>
            </Box>
            <BodyText size={theme.typography.bodySmall} css={classes.comment}>{comment}</BodyText>
        </Box>
    );
};

export default Testimonial;
