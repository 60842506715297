
/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, Footer, TitleSection } from '../../components/common';
import { ContactFormSection } from './components';
import { seoConfig } from '../../config';

const ForParents = () => {
    return (
        <>
        <SEO 
            title={seoConfig.contact.title}
            description={seoConfig.contact.description}
            keywords={seoConfig.contact.keywords}
        />
        <header>
            <Menu />
        </header>
        <main>
            <section>
                <TitleSection englishTitle="Form" japaneseTitle="お問い合わせ" />
            </section>
            <section>
                <ContactFormSection />
            </section>
        </main>
        <footer>
            <Footer />
        </footer>
        </>
    );
};

export default ForParents;
