/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import useCustomButtonStyles from './CustomButtonStyles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CustomButton = ({ size, width, minWidth, children, variant, onClick, colorVariant }) => {
  const theme = useTheme();
  const classes = useCustomButtonStyles(theme, size, width, minWidth, colorVariant);

  return (
    <Button css={classes.button} onClick={onClick}>
      <Box className="leftBox" css={classes.leftBox}>
        <Box>
          <Typography variant={variant}>{children}</Typography>
        </Box>
        <Box css={classes.leftTrapezoid}></Box>
      </Box>
      <Box className="rightBox" css={classes.rightBox}>
        <Box css={classes.rightTrapezoid}></Box>
        <Box css={classes.alignCenterBox}>
          <ArrowForwardIcon fontSize="small" />
        </Box>
      </Box>
    </Button>
  );
};

export default CustomButton;
