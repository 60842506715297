/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BodyText, H3Text } from '../../../../../../components/ui';
import ChallengeContentStyles from './ChallengeContentStyles';

const ChallengeContent = ({ src, title, description }) => {
    const theme = useTheme();
    const classes = ChallengeContentStyles(theme);

    return (
        <Box css={classes.container}>
            <img src={src} alt={title} css={classes.challegeImage} />
            <H3Text size={theme.typography.titleSmall} css={classes.title}>{title}</H3Text>
            <BodyText size={theme.typography.bodySmall} css={classes.description}>{description}</BodyText>
        </Box>
    );
};

export default ChallengeContent;
