import { useNavigate } from 'react-router-dom';

const useNavigateToUrl = () => {
  const navigate = useNavigate();

  const navigateToUrl = (url) => {
    navigate(url);
  };

  return navigateToUrl;
};

export default useNavigateToUrl;
