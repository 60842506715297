import { css } from '@emotion/react';

const CustomDropDownListStyles = (theme) => ({
    formControl: css({
        display: 'flex',
        width: '100%',
        height: '64px',
        marginBottom: '16px',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    }),
    label: css({
        flexBasis: '30%',
        textAlign: 'left',
        marginRight: 16,
        fontWeight: 'bold',
    }),
    ddl: css({
        flexBasis: '70%',
    }),
    selectField: css({
        height: '40px',
        '& *': {
            padding: '0px',
        },
    }),
});

export default CustomDropDownListStyles;
