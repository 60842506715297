import { css } from '@emotion/react';

const BenefitsSectionStyles = (theme) => ({
    container: css({
        padding: '100px 0',
        [theme.breakpoints.down('md')]: {
            padding: '50px 0',
        }
    }),
    wrapper: css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: theme.width.max,
        margin: '0 auto',
    }),
    title: css({
        fontWeight: 'bold',
        paddingBottom: '20px',
        marginBottom: '80px',
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    }),
    benefitsContainer: css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }),
});

export default BenefitsSectionStyles;
