/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import CarouselStyles from './CarouselStyles';

const Carousel = ({ images }) => {
    const theme = useTheme();
    const classes = CarouselStyles(theme);

    const repeatedImages = [...images, ...images, ...images];

    return (
        <Box css={classes.carousel}>
            <Box className="inner-carousel">
                {repeatedImages.map((image, index) => (
                    <Box
                        key={index}
                        css={classes.imageBox}
                    >
                        <img src={image.src} alt={`carousel-image-${index}`} />
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Carousel;
