import { css } from '@emotion/react';

const FeatureStyles = (theme, reverse) => ({
    container: css({
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        width: '80%', 
        margin: '50px auto',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            margin: '20px auto',
        }
    }),
    imageContainer: css({
        flex: '0 0 40%',
        order: reverse ? 2 : 1,
        [theme.breakpoints.down('md')]: {
            order: 2,
            display: 'flex',
            justifyContent: 'center',
        }
    }),
    image: css({
        width: '100%',
        borderRadius: '50px',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            margin: '20px',
            aspectRatio: '3 / 2',
        },
    }),
    contentContainer: css({
        flex: 1,
        order: reverse ? 1 : 2,
        paddingLeft: reverse ? '0' : '40px',
        paddingRight: reverse ? '40px' : '0', 
        [theme.breakpoints.down('md')]: {
            order: 1,
            padding: '0  20px',
        }
    }),
    title: css({
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        marginBottom: '10px',
    }),
    message: css({
        fontWeight: 'bold',
    }),
});

export default FeatureStyles;
