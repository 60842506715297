/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box } from '@mui/material';
import { Content } from './components';
import { contents } from '../../../../config';

const ContentsSection = () => {

    return (
        <Box>
            {contents.map((content, index) => (
                <Content
                    key={index}
                    title={content.title}
                    message={content.message}
                    reverse={content.reverse}
                    url={content.url}
                    imagePath={content.imagePath}
                />
            ))}
        </Box>
    );
};

export default ContentsSection;
