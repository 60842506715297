import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes/theme';
import { TopPage, AboutTravelingDanceClasses, Contact, 
         AboutUs, TrialApplication,
         PrivacyPolicy, RukhKids, AboutAdultHipHop, 
         AboutRukhFit,
         BenefitDance
       } from './pages';
import { HelmetProvider } from 'react-helmet-async';
import { urls } from './config';

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path={urls.top} element={<TopPage />} />
            <Route path={urls.aboutRukhFit} element={<AboutRukhFit />} />
            <Route path={urls.aboutTravelingDanceClasses} element={<AboutTravelingDanceClasses />} />
            <Route path={urls.contact} element={<Contact />} />
            <Route path={urls.aboutUs} element={<AboutUs />} />
            <Route path={urls.trial} element={<TrialApplication />} />
            <Route path={urls.aboutRukhKids} element={<RukhKids />} />
            <Route path={urls.aboutAdultHipHop} element={<AboutAdultHipHop />} />
            <Route path={urls.benefitDance} element={<BenefitDance />} />
            <Route path={urls.privacyPolicy} element={<PrivacyPolicy />}/>
          </Routes>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
