import { css } from '@emotion/react';

const ChallengeContentStyles = (theme) => ({
    container: css({
        width: '30%',
        textAlign: 'center',

        [theme.breakpoints.down('md')]: {
            width: '70%',
            marginBottom: '35px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }),
    challegeImage: css({
        width: '100%',
        aspectRatio: '16/9',
        marginBottom: '16px',
        borderRadius: '10px',
    }),
    title: css({
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontWeight: 'bold',
        height: '100px',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            marginBottom: '16px',
        },
    }),
    description: css({
        textAlign: 'left',
    }),
});

export default ChallengeContentStyles;
