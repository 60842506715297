import { useState, useEffect } from 'react';
import axios from 'axios';
import { urls } from '../config';

const useGetCSRFToken = () => {
  const [csrfToken, setCsrfToken] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCSRFToken = async () => {
      try {
        const response = await axios.get(`${urls.api}/web/get-csrf-token/`);
        setCsrfToken(response.data.csrfToken);  // CSRFトークンを状態に保存
      } catch (err) {
        console.error('Error fetching CSRF token:', err);
        setError('Error fetching CSRF token');
      }
    };

    fetchCSRFToken();
  }, []);  // 初回レンダリング時に実行

  return { csrfToken, error };  // CSRFトークンとエラーを返す
};

export default useGetCSRFToken;
