/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { H2Text, BodyText } from '../../ui';
import MessageSectionStyles from './MessageSectionStyles';

const MessageSection = ({ title, message, id }) => {
  const theme = useTheme();
  const classes = MessageSectionStyles(theme);

  return (
    <Box css={classes.container} id={id}>
      <Box css={classes.wrapper}>
        <H2Text size={theme.typography.display} css={classes.title}>{title}</H2Text>
        <BodyText
            size={theme.typography.bodyLarge}
            css={classes.message}
            dangerouslySetInnerHTML={{ __html: message }}
        />
      </Box>
    </Box>
  );
};

export default MessageSection;
