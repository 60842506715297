import { css } from '@emotion/react';

const InstagramGridSectionStyles = (theme) => ({
  container: css({
    width: '100%',
  }),
  wrapper: css({
    width: 'calc(100% - 100px)',
    maxWidth: theme.width.max || '1200px',
    margin: '0 auto',
  }),
  title: css({
    fontWeight: 'bold',
    margin: '0 0 20px 20px',
  }),
  cardWrapper: css({
    padding: '0 10px',
  }),
  card: css({
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    aspectRatio: '3 / 5', // 縦長のリール形式
    overflow: 'hidden',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)', // 影の追加
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxHeight: '500px',
    height: 'auto',
    transition: 'box-shadow 0.3s ease', // ホバー時のアニメーション
    '&:hover': {
      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.25)', // ホバー時に影を強調
    }
  }),
  media: css({
    width: '100%',
    height: '100%',
    objectFit: 'cover', // 画像や動画をカード内でフィットさせる
  }),
  overlay: css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', 
    color: '#fff',
    opacity: 0,
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: 1,
    },
    span: {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  }),
});

export default InstagramGridSectionStyles;
