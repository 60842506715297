const faqData = [
  {
    question: 'なぜ運動は健康に良いのですか？',
    answer: '運動は心肺機能を向上させ、筋肉を強化し、ストレスを軽減する効果があります。定期的な運動は長寿にも寄与します。',
    supplement: '運動は、血液循環を改善し、心臓と肺の機能を強化します。また、筋肉と骨を強くし、ストレスを減少させる効果があります。さらに、定期的な運動は、全体的な健康状態を改善し、寿命を延ばすことが知られています。'
  },
  {
    question: 'どのくらいの頻度で運動すべきですか？',
    answer: '健康維持のためには、週に少なくとも150分の中強度の運動、または75分の高強度の運動が推奨されています。',
    supplement: '健康を維持するためには、週に少なくとも150分の中強度の有酸素運動、または75分の高強度の有酸素運動が必要です。また、筋力トレーニングも週に2回以上行うことが推奨されています。'
  },
  {
    question: 'バランスの取れた食事とは何ですか？',
    answer: 'バランスの取れた食事は、適切な量の炭水化物、タンパク質、脂肪、ビタミン、ミネラルを含む食事のことです。これにより、体の機能が正常に保たれ、健康を維持することができます。',
    supplement: 'バランスの取れた食事は、体が必要とするすべての栄養素を適切に摂取することを意味します。これには、炭水化物、タンパク質、脂肪、ビタミン、ミネラルが含まれます。これにより、エネルギーを提供し、細胞の修復と成長を促進し、免疫機能をサポートします。'
  },
  {
    question: '水を十分に飲むことの重要性は何ですか？',
    answer: '水分を十分に摂取することで、体の正常な機能を維持し、脱水症状を防ぐことができます。特に運動中や暑い日には水分補給が重要です。',
    supplement: '水は体のほとんどの機能に不可欠です。水分補給により、体温の調節、老廃物の排出、関節の潤滑が行われます。十分な水分を摂取することで、集中力やエネルギーレベルも向上します。'
  },
];

export default faqData;
