/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, TitleSection, ContactSection, EventsSection, Footer } from '../../components/common';
import {
  CorporatePhilosophySection, GreetingSection, MissionSection,
  ValueSection, VisionSection
} from './components';
import { seoConfig } from '../../config';

const AboutUs = () => {
  return (
    <>
      <SEO 
        title={seoConfig.aboutUs.title} 
        description={seoConfig.aboutUs.description}
        keywords={seoConfig.aboutUs.keywords}
      />
      <header>
        <Menu />
      </header>
      <main>
        <section>
            <TitleSection englishTitle="About Us" japaneseTitle="RUKHについて" />
        </section>
        <section>
            <CorporatePhilosophySection />
        </section>
        <section>
            <MissionSection />
        </section>
        <section>
            <VisionSection />
        </section>
        <section>
            <ValueSection />
        </section>
        <section>
            <GreetingSection />
        </section>
        <section>
            <EventsSection />
        </section>
        <section>
            <ContactSection />
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default AboutUs;
