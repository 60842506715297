import { css } from '@emotion/react';

const SolutionSectionStyles = (theme) => ({
    container: css({
        marginTop: '20px',
        width: '100%',
        padding: '0 40px',
    }),
    wrapper: css({
        width: '100%',
        maxWidth: theme.width.max,
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    }),
    title: css({
        marginBottom: '40px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    }),
    content: css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '20px',
        borderRadius: '50px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        marginBottom: '60px',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            textAlign: 'center',
        },
    }),
    buttonContainer: css({
        position: 'absolute',
        right: '20px',
        bottom: '20px',
        [theme.breakpoints.down('md')]: {
            position: 'static',
            marginTop: '20px',
            textAlign: 'center',
        },
    }),
    textContainer: css({
        flex: '2',
        textAlign: 'left',
        paddingRight: '50px',
        [theme.breakpoints.down('md')]: {
            paddingRight: '0',
        },
    }),
    textWithButtonContainer: css({
        flex: '2',
        textAlign: 'left',
        paddingRight: '50px',
        [theme.breakpoints.down('md')]: {
            paddingRight: '0',
            paddingBottom: '0',
        },
        paddingBottom: '50px',
    }),
    subTitle: css({
        color: theme.palette.primary.main,
        marginBottom: '20px',
        fontWeight: 'bold',
    }),
    description: css({
        marginBottom: '10px',
        fontWeight: 'bold',
    }),
    buttonMinWidth: '240px',
});

export default SolutionSectionStyles;
