import { css } from '@emotion/react';

const ContentStyles = (theme, reverse) => {
    const aspectRatio = '2 / 1';
    const paddingValues = `calc((60% / ${aspectRatio}) * 0.9 * 0.5)`;

    return {
        container: css({
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '100px auto',
            maxWidth: theme.width.max,
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column-reverse',
                margin: '70px auto',
            },
        }),
        square1: css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '60%',
            maxWidth: '900px',
            height: 'auto',
            aspectRatio: aspectRatio,
            borderRadius: '50px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            [reverse ? 'left' : 'right']: '-10%',
            paddingLeft: reverse ? '30px' : paddingValues,
            paddingRight: reverse ? paddingValues : '30px',
            paddingTop: '40px',
            paddingBottom: '40px',
            [theme.breakpoints.down('md')]: {
                position: 'static',
                paddingLeft: '30px',
                paddingRight: '30px',
                width: '90%',
                height: '100%',
                aspectRatio: 'auto',
                margin: '20px 0',
            },
        }),
        title: css({
            marginBottom: '35px',
            fontWeight: 'bold',
            [theme.breakpoints.down('md')]: {
                marginBottom: '10px',
            },
        }),
        message: css({
            marginBottom: '30px',
            fontWeight: 'bold',
        }),
        square2: css({
            width: `calc((60% / ${aspectRatio}) * 0.9)`,
            maxWidth: `calc((900px / ${aspectRatio}) * 0.9)`,
            height: 'auto',
            aspectRatio: '1 / 1',
            borderRadius: '50px',
            overflow: 'hidden',
            position: 'absolute',
            [reverse ? 'right' : 'left']: '13%',
            [theme.breakpoints.down('md')]: {
                position: 'static',
                width: '90%',
                maxWidth: '90%',
                aspectRatio: '368 / 211',
            },
        }),
        customButtonMinWidth: '200px',
        customButtonWidth: '70%',
        customButtonMdWidth: '60%',
        image: css({
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: 'inherit',
        }),
    };
};

export default ContentStyles;
