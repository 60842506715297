import urls from './urls';

const contents = [
    {
        title: "私たちの思い",
        message: "私たちの活動の根底には、ダンスと運動を通じて心と体の健康を育む強い想いがあります。地域社会に根ざし、すべての世代の人々が安心して楽しめる環境作りを目指しています。子供から大人まで、誰もが笑顔で成長できる場を提供しています。",
        reverse: true,
        url: urls.aboutUs,
        imagePath: '/images/100TopPage/104Contents1.png',
    },
    {
        title: "RUKH KID'S",
        message: "子どもたちが楽しみながら自己表現力と協調性を育み、自信を持って成長できる環境を提供します。<br/>ダンスや運動を通じて、未来に向けた成長ステージをサポートします。",
        reverse: false,
        url: urls.aboutRukhKids,
        imagePath: '/images/100TopPage/104Contents2.png',
    },
    {
        title: "RUKH FIT",
        message: "すべての世代に活力と健康を届ける「RUKH FIT」。<br/>体に無理のない運動で、心と体のバランスを整え、健康的な生活をサポートします。",
        reverse: true,
        url: urls.aboutRukhFit,
        imagePath: '/images/100TopPage/104Contents3.png',
    },
];

export default contents;
