import { css } from '@emotion/react';

const CorporatePhilosophySectionStyle = (theme) => ({
    container: css({
        width: '100%',
        padding: '60px 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        }
    }),
    wrapper: css({
        maxWidth: '900px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        padding: '40px',
        [theme.breakpoints.down('sm')]: {
            gap: '10px',
            padding: '10px',
        }
    }),
    h2Text: css({
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    }),
    text: css({
        fontWeight: 'bold',
    })
});

export default CorporatePhilosophySectionStyle;
