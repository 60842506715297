/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material';
import FooterStyles from './FooterStyles';
import { BottomContainer, FooterNav } from './components';
import { H2Text } from '../../ui';

const Footer = () => {
    const theme = useTheme();
    const classes = FooterStyles(theme);

    return (
        <Box component="footer" css={classes.footer}>
            <H2Text size={theme.typography.invisible}>フッター</H2Text>
            <FooterNav />
            <Box css={classes.logoContainer}>
                <img src="/whiteLogo.png" alt="Rukh Logo" css={classes.logo} />
            </Box>
            <Box css={classes.separator} />
            <BottomContainer />
        </Box>
    );
}

export default Footer;
