/** @jsxImportSource @emotion/react */
import React from 'react';
import { Typography } from '@mui/material';

const CustomText = ({ variant, size, css: customCss, ...props }) => {
    const combinedStyles = {
        ...size,
        ...customCss,
    }
    return <Typography css={combinedStyles} variant={variant} {...props} />;
};

export const H1Text = (props) => (
    <CustomText variant="h1" {...props} />
);

export const H2Text = (props) => (
    <CustomText variant="h2" {...props} />
);

export const H3Text = (props) => (
    <CustomText variant="h3" {...props} />
);

export const H4Text = (props) => (
    <CustomText variant="h4" {...props} />
);

export const BodyText = (props) => (
    <CustomText variant="body1" {...props} />
);

export default CustomText;
