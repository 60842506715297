/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box, FormControl, FormControlLabel, Checkbox, FormHelperText } from '@mui/material';
import { BodyText } from '../CustomText/CustomText';
import CustomMultipleSelectStyles from './CustomMultipleSelectStyles';

const CustomMultipleSelect = ({ label, options, values, onChange, error, helperText }) => {
  const theme = useTheme();
  const classes = CustomMultipleSelectStyles(theme);

  return (
    <Box css={classes.formControl}>
      <BodyText size={theme.typography.body} css={classes.label}>
        {label}
      </BodyText>
      <FormControl error={Boolean(error)} css={classes.checkboxContainer}>
        {options.map((option) => (
          <FormControlLabel
            key={option.name}
            control={
              <Checkbox
                checked={values[option.name]}
                onChange={onChange}
                name={option.name}
                css={classes.checkbox}
              />
            }
            label={
                <BodyText size={theme.typography.smallText} css={classes.checkboxLabel}>{option.label}</BodyText>
            }
          />
        ))}
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default CustomMultipleSelect;
