/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Link, useTheme } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import FooterNavStyles from './FooterNavStyles';
import { navItems } from '../../../../../config';
import { BodyText, H3Text, H4Text  } from '../../../../ui';

const FooterNav = () => {
    const theme = useTheme();
    const classes = FooterNavStyles(theme);

    return (
        <nav css={classes.navContainer}>
            <H3Text size={theme.typography.invisible}>ナビゲーションメニュー</H3Text>
            {navItems.map((item, index) => (
                <Box key={index} css={classes.navColumn}>
                    <Link href={item.url} css={classes.navLink}>
                        <H4Text size={theme.typography.bodySmall} style={classes.navTitle}>
                            {item.title}
                        </H4Text>
                        <CallMadeIcon css={classes.navIcon} />
                    </Link>
                    {item.subItems && (
                        item.subItems.map((subItem, subIndex) => (
                            <Link href={subItem.url} key={subIndex} css={classes.navItemLink}>
                                <BodyText size={theme.typography.body} style={classes.navItem}>
                                    {subItem.text}
                                </BodyText>
                            </Link>
                        ))
                    )}
                </Box>
            ))}
        </nav>
    );
};

export default FooterNav;
