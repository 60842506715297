import { useState } from 'react';
import axios from 'axios';
import { urls } from '../../../../../../config'
import { useGetCSRFToken } from '../../../../../../utils';

const useContactForm = () => {
  const [formData, setFormData] = useState({ name: '', furiname: '', phone: '', email: '', confirmEmail: '',preContact: '', message: '', agree: false });
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('');
  const [open, setOpen] = useState(false);
  const { csrfToken, loading } = useGetCSRFToken();
  const [submitting, setSubmitting] = useState(false);  // 送信中の状態を管理

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    if (!formData.name) {
      tempErrors.name = true;
      isValid = false;
    }
    if (!formData.furiname) {
      tempErrors.furiname = true;
      isValid = false;
    }
    if (!formData.phone) {
      tempErrors.phone = "電話番号を入力してください";
      isValid = false;
    } else if (!/^\d{10,11}$/.test(formData.phone)) {
      tempErrors.phone = "有効な電話番号を入力してください";
      isValid = false;
    }
    if (!formData.email) {
      tempErrors.email = true;
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = true;
      isValid = false;
    }
    if (!formData.preContact) {
      tempErrors.preContact = true;
      isValid = false;
    }
    if (!formData.confirmEmail) {
      tempErrors.confirmEmail = true;
      isValid = false;
    } else if (formData.email !== formData.confirmEmail) {
      tempErrors.confirmEmail = true;
      isValid = false;
    }
    if (!formData.message) {
      tempErrors.message = true;
      isValid = false;
    }
    if (!formData.agree) {
      tempErrors.agree = true;
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    if (loading) {
      console.log('CSRF token is loading...');
      return;
    }
    if (!csrfToken) {
      console.error('No CSRF token available');
      return;
    }

    setSubmitting(true);

    try {
      const response = await axios.post(
        `${urls.api}/web/send-contact-gmail/`,
        JSON.stringify(formData),
        {
          headers: {
            'X-CSRFToken': csrfToken,
            'Content-Type': 'application/json',
          },
        }
      );
      setStatus(response.data.status);
      setFormData({ name: '', furiname: '', phone: '', email: '', confirmEmail: '',preContact: '', message: '', agree: false });  // 成功時にフォームをクリア
    } catch (error) {
      setStatus('Error sending message');
    } finally {
      setSubmitting(false); 
    }
  };

  const handleDialogOpen = () => {
    if (validateForm()) {
      setOpen(true);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDialogSubmit = () => {
    handleSubmit();
    setOpen(false);
  };

  return {
    formData,
    errors,
    status,
    open,
    submitting,
    handleChange,
    handleDialogOpen,
    handleDialogClose,
    handleDialogSubmit,
  };
};

export default useContactForm;
