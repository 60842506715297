import { css } from '@emotion/react';

const ConfirmDialogStyles = (theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '80vh', // ダイアログ全体の高さをウィンドウの80%に設定
  },
  dialogTitle: {
    backgroundColor: '#bdbdbd',
    color: theme.palette.common.white,
    padding: '20px 30px',
    fontWeight: 'bold',
  },
  dialogContent: {
    padding: '20px 30px',
    overflowY: 'scroll', // スクロール可能にする
    scrollbarWidth: 'thin', // Firefox対応: スクロールバーの幅を細く
    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`, // Firefox対応: スクロールバーの色
    '&::-webkit-scrollbar': {
      width: '8px', // スクロールバーの幅を設定
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.background.paper, // スクロールバーのトラックの背景色
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main, // スクロールバーの色
      borderRadius: '10px', // スクロールバーの角を丸くする
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.primary.dark, // ホバー時の色
    },
  },
  dialogContentText: {
    fontSize: theme.typography.bodyLarge.fontSize,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '30px 0',
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: '20px',
  },
  contentContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 50px',
    paddingBottom: '15px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      margin: '10px 0',
    },
  }),
  contentText: css({
    fontWeight: 'bold',
  }),
  dialogActions: css({
    justifyContent: 'space-around',
    padding: '40px 0 60px 0',
    '& > *:not(:first-of-type)': {
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 0 30px 0',
      justifyContent: 'space-between',
      height: '120px',
    },
  }),
  customButtonSize: 'small',
  customButtonWidth: '155px',
});

export default ConfirmDialogStyles;
