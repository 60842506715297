/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import useTestimonialsSection from './UseTestimonialsSection';
import TestimonialsSectionStyles from './TestimonialsSectionStyles';
import testimonials from '../../../../config/testimonials';
import { TitleWithButtons, Carousel } from './components';

const TestimonialsSection = () => {
    const theme = useTheme();
    const classes = TestimonialsSectionStyles(theme);
    const {
        handleTransitionEnd,
        slideStyle,
        getSlideItems,
        handleNextButton,
        handlePrevButton,
    } = useTestimonialsSection(testimonials);

    const visibleItems = getSlideItems();

    return (
        <Box css={classes.container}>
            <TitleWithButtons
                handlePrevButton={handlePrevButton}
                handleNextButton={handleNextButton}
            />
            <Carousel
                handleTransitionEnd={handleTransitionEnd}
                visibleItems={visibleItems}
                slideStyle={slideStyle}
            />
        </Box>
    );
};

export default TestimonialsSection;
