import { css } from '@emotion/react';

const FeatureTitleStyles = (theme) => ({
    textContainer: css({
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: '120px',
        [theme.breakpoints.down('md')]: {
            marginBottom: '30px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '60px',
        },
    }),
    title: css({
        fontWeight: 'bold',
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        padding: '20px 50px',
        [theme.breakpoints.down('md')]: {
            padding: '10px 40px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '5px 0 5px 20px',
        }
    }),
    subTitle: css({
        fontWeight: 'bold',
        padding: '20px 40% 20px 50px',
        [theme.breakpoints.down('md')]: {
            padding: '10px 40% 10px 40px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '8px 20px',
        }
    }),
    message: css({
        fontWeight: 'bold',
        padding: '0 40% 0 50px ',
        [theme.breakpoints.down('md')]: {
            padding: '0 40% 0 40px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 20px',
        }
    }),
    image: css({
        position: 'absolute',
        right: '0',
        top: '0',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    }),
});

export default FeatureTitleStyles;
