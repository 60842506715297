/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import EventsSectionStyles from './EventsSectionStyles';
import { BodyText, CustomButton, H2Text } from '../../ui';
import { useNavigateToUrl } from '../../../utils';
import { urls } from '../../../config';

const EventsSection = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = EventsSectionStyles(theme, isMd, isSm);
    const navigateToUrl = useNavigateToUrl();

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <img
                    src='/images/004EventsSection/dancePerson.png'
                    alt='ダンスをしている人'
                    css={[classes.dancePersonImage, classes.image]}
                />
                <img
                    src='/images/004EventsSection/girlOnRocket.png'
                    alt='ロケットに乗っている少女'
                    css={[classes.girlOnRocketImage, classes.image]}
                />
                <Box css={classes.content}>
                    <H2Text size={theme.typography.titleSmall} css={classes.title}>
                        RUKH KID`Sの
                        {isMd && <br />}
                        体験・見学会の申し込みはこちら
                    </H2Text>
                    <BodyText size={theme.typography.body} css={classes.message}>
                        一緒に楽しく踊りましょう！
                        {isMd && <br />}
                        初心者も大歓迎です！
                        <br />
                        新しい友達と一緒にリズムに乗って、
                        {isMd && <br />}
                        ダンスの魅力を体感しましょう！
                    </BodyText>
                    <CustomButton
                        size={classes.buttonSize}
                        minWidth={classes.buttonMinWidth}
                        width={classes.buttonWidth}
                        onClick={() => navigateToUrl(urls.trial)}
                        colorVariant={'whiteBackground'}
                    >
                        <BodyText style={classes.bodyText}>体験・見学会を申し込む</BodyText>
                    </CustomButton>
                </Box>
            </Box>
        </Box>
    );
}

export default EventsSection;
