import React from 'react';
import { Box, Typography } from '@mui/material';
import { Menu, Footer, SEO } from '../../components/common';
import { seoConfig } from '../../config';

const PrivacyPolicy = () => {
    return (
        <>
            <SEO 
                title={seoConfig.privacyPolicy.title}
                description={seoConfig.privacyPolicy.description}
                keywords={seoConfig.privacyPolicy.keywords}
            />
            <header>
                <Menu />
            </header>
            <main>
                <Box sx={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
                    <Typography variant="h4" gutterBottom>プライバシーポリシー</Typography>
                    
                    <Typography variant="body1" paragraph>
                        合同会社RUKH（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
                    </Typography>
                    
                    <Typography variant="h6" gutterBottom>第1条（個人情報）</Typography>
                    <Typography variant="body1" paragraph>
                        「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
                    </Typography>

                    <Typography variant="h6" gutterBottom>第2条（個人情報の収集について - 問い合わせおよび体験会申込フォーム）</Typography>
                    <Typography variant="body1" paragraph>
                        当社では、ユーザーが問い合わせフォームや体験会申込フォームを利用する際に、氏名、住所、電話番号、メールアドレスなどの個人情報を収集することがあります。これらの情報は、ユーザーからの問い合わせへの回答や、体験会の案内および予約の確認、その他サービスの提供のために使用されます。
                        当社は、これらの個人情報をユーザーの同意なく第三者に提供することはありません。
                    </Typography>

                    <Typography variant="h6" gutterBottom>第3条（個人情報を収集・利用する目的）</Typography>
                    <Typography variant="body1" paragraph>
                        当社が個人情報を収集・利用する目的は，以下のとおりです。
                        <ul>
                            <li>当社サービスの提供・運営のため</li>
                            <li>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</li>
                            <li>ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため</li>
                            <li>メンテナンス，重要なお知らせなど必要に応じたご連絡のため</li>
                            <li>利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため</li>
                            <li>上記の利用目的に付随する目的</li>
                        </ul>
                    </Typography>

                    <Typography variant="h6" gutterBottom>第4条（画像の使用について）</Typography>
                    <Typography variant="body1" paragraph>
                        当社ウェブサイト上に掲載された画像やコンテンツは、著作権法およびその他の関連法令に基づき保護されています。当社の許可なく、これらの画像やコンテンツを無断で使用、転載、または配布することを禁止します。 
                        ユーザーが提出した画像やメディアも同様に、当社の許可を得て使用されるものであり、無断での使用は行われません。
                    </Typography>

                    <Typography variant="h6" gutterBottom>第5条（利用目的の変更）</Typography>
                    <Typography variant="body1" paragraph>
                        当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
                    </Typography>

                    <Typography variant="body1" paragraph>
                        合同会社RUKH
                    </Typography>
                </Box>
            </main>
            <footer>
                <Footer />
            </footer>
        </>
    );
};

export default PrivacyPolicy;
