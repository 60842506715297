/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import HipHopCardStyles from './HipHopCardStyles';
import { BodyText, H4Text, CustomButton } from '../../../../components/ui';
import { urls } from '../../../../config';
import { useNavigateToUrl } from '../../../../utils';

const HipHopCard = () => {
    const theme = useTheme();
    const classes = HipHopCardStyles(theme);
    const navigateToUrl = useNavigateToUrl();

    return (
        <Box css={classes.container} id='hiphop-class'>
            <Box css={classes.card}>
                <H4Text size={theme.typography.titleSmall} css={classes.title}>大人のヒップホップ教室</H4Text>
                
                <Box css={classes.info}>
                    <BodyText size={theme.typography.body} css={classes.text}>場所：</BodyText>
                    <BodyText size={theme.typography.body} css={classes.text}>大森の里（大森インターすぐ）</BodyText>
                    <BodyText size={theme.typography.body} css={classes.text}>愛知県名古屋市守山区大森１丁目１０２</BodyText>
                </Box>
                
                <Box css={classes.info}>
                    <BodyText size={theme.typography.body} css={classes.text}>日時：</BodyText>
                    <BodyText size={theme.typography.body} css={classes.text}>水曜日　１９：３０ - ２０：３０</BodyText>
                </Box>
                
                <Box css={classes.info}>
                    <BodyText size={theme.typography.body} css={classes.text}>料金：</BodyText>
                    <BodyText size={theme.typography.body} css={classes.text}>月　謝　６,０００円</BodyText>
                    <BodyText size={theme.typography.body} css={classes.text}>３回券　５,０００円（３か月以内)</BodyText>
                    <BodyText size={theme.typography.body} css={classes.text}>１回券　２,０００円</BodyText>
                    <BodyText size={theme.typography.body} css={classes.text}>体　験　５００円</BodyText>
                </Box>

                <Box css={classes.button}>
                    <CustomButton
                        minWidth={classes.buttonMinWidth}
                        width={classes.buttonMinWidth}
                        onClick={() => navigateToUrl(urls.contact)}
                    >
                        <BodyText style={classes.smallText}>申し込みはこちらから</BodyText>
                    </CustomButton>
                </Box>
            </Box>
        </Box>
    );
};

export default HipHopCard;
