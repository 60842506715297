/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Typography, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import UseApplicationForm from './UseApplicationForm';
import ApplicationFormStyles from './ApplicationFormStyles';
import { CustomMultipleSelect, CustomDropDownList, CustomRadioGroup, 
         CustomTextField, CustomButton, BodyText 
       } from '../../../../../../components/ui';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { urls } from '../../../../../../config';

const ApplicationForm = () => {
  const theme = useTheme();
  const { state, errors, status, open, submitting, handleChange, handleDialogOpen, handleDialogClose, handleDialogSubmit } = UseApplicationForm();
  const classes = ApplicationFormStyles(theme);

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()} css={classes.form}>
        <CustomTextField
          label="*保護者様のお名前"
          name="parentName"
          value={state.parentName}
          onChange={handleChange}
          error={errors.parentName}
          helperText={errors.parentName}
        />
        <CustomTextField
          label="*保護者様のふりがな"
          name="parentKana"
          value={state.parentKana}
          onChange={handleChange}
          error={errors.parentKana}
          helperText={errors.parentKana}
        />
        <CustomTextField
          label="*電話番号"
          name="phoneNumber"
          value={state.phoneNumber}
          onChange={handleChange}
          error={errors.phoneNumber}
          helperText={errors.phoneNumber}
        />
        <CustomTextField
          label="*メールアドレス"
          name="email"
          value={state.email}
          onChange={handleChange}
          error={errors.email}
          helperText={errors.email}
        />
        <CustomTextField
          label="*メールアドレス（確認用）"
          name="confirmEmail"
          value={state.confirmEmail}
          onChange={handleChange}
          error={errors.confirmEmail}
          helperText={errors.confirmEmail}
        />
        <CustomRadioGroup
          label="*優先連絡手段"
          name="preContact"
          value={state.preContact}
          onChange={handleChange}
          options={[
            { value: '電話', label: '電話' },
            { value: 'メール', label: 'メール' },
          ]}
          error={errors.preContact}
          helperText={errors.preContact}
        />
        <CustomMultipleSelect
          label="*ご希望内容"
          options={[
            { name: '教室の見学', label: '教室の見学' },
            { name: 'プログラム体験', label: 'プログラム体験' },
            { name: 'まずは相談したい', label: 'まずは相談したい' },
          ]}
          values={state}
          onChange={handleChange}
          error={errors.requestContent}
          helperText={errors.requestContent}
        />
        <CustomTextField
          label="*所属施設"
          name="affiliation"
          value={state.affiliation}
          onChange={handleChange}
          error={errors.affiliation}
          helperText={errors.affiliation}
        />
        <CustomRadioGroup
          label="*利用時期のご希望"
          name="desiredTime"
          value={state.desiredTime}
          onChange={handleChange}
          options={[
            { value: '今すぐ', label: '今すぐ' },
            { value: '１か月以内', label: '１ヶ月以内' },
            { value: '未定', label: '未定' },
          ]}
          error={errors.desiredTime}
          helperText={errors.desiredTime}
        />
        <CustomDropDownList
          label="*お子様の学年"
          name="childGrade"
          value={state.childGrade}
          onChange={handleChange}
          options={[
            { value: '年小', label: '年小' },
            { value: '年中', label: '年中' },
            { value: '年長', label: '年長' },
            { value: '１学年', label: '１学年' },
            { value: '２学年', label: '２学年' },
            { value: '３学年', label: '３学年' },
            { value: '４学年', label: '４学年' },
            { value: '５学年', label: '５学年' },
            { value: '６学年', label: '６学年' },
            { value: 'その他', label: 'その他' },
          ]}
          error={errors.childGrade}
          helperText={errors.childGrade}
        />
        <CustomTextField
          label="*郵便番号(ハイフンなし)"
          name="postalCode"
          value={state.postalCode}
          onChange={handleChange}
          error={errors.postalCode}
          helperText={errors.postalCode}
        />
        <CustomTextField
          label="*住所"
          name="address"
          value={state.address}
          onChange={handleChange}
          error={errors.address}
          helperText={errors.address}
        />
        <CustomDropDownList
          label="Rukhはどこで知りましたか？"
          name="source"
          value={state.source}
          onChange={handleChange}
          options={[
            { value: 'インターネット', label: 'インターネット' },
            { value: '友人・知人', label: '友人・知人' },
            { value: 'イベント', label: 'イベント' },
            { value: 'その他', label: 'その他' },
          ]}
          error={errors.source}
          helperText={errors.source}
        />
        {errors.agree && (
            <BodyText size={theme.typography.smallText} css={classes.errorPrivacyPolicy} color="error">
                プライバシーポリシーに同意してください。
            </BodyText>
        )}
        <FormControlLabel
            control={
                <Checkbox
                    checked={state.agree}
                    onChange={handleChange}
                    name="agree"
                    color="primary"
                />
            }
            label={
                <Typography
                    component="a"
                    href={urls.privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'blue', textDecoration: 'none' }}
                >
                    プライバシーポリシーに同意する
                </Typography>
            }
            css={classes.privacyPolicy}
        />
        <CustomButton
          size={classes.customButtonSize}
          width={classes.customButtonWidth}
          minWidth={classes.customButtonMinWidth}
          variant="button"
          onClick={handleDialogOpen}
          disabled={submitting}
        >
          入力内容の確認
        </CustomButton>

        <ConfirmDialog
          formData={state}
          open={open}
          handleDialogClose={handleDialogClose}
          handleDialogSubmit={handleDialogSubmit}
        />
      </form>

    {submitting && <CircularProgress />}

    {status && (
    <Typography
        variant="body1"
        css={classes.statusMessage(status.startsWith('Error'))}
    >
        {status}
    </Typography>
    )}
    </>
  );
};

export default ApplicationForm;
