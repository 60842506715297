import { css } from '@emotion/react';

const FAQStyles = (theme) => ({
  faqCard: css({
    borderRadius: '16px',
    boxShadow: theme.shadows[3],
    marginBottom: '30px',
  }),
  cardContent: css({
    '&:last-child': {
      paddingBottom: '16px',
     } 
  }),
  faqQuestion: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  }),
  faqTitle: css({
    display: 'flex',
  }),
  q: css({
    color: theme.palette.primary.main,
    marginLeft: '10px',
    marginRight: '20px',
  }),
  answer: css({
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: '20px'
  }),
  faqAnswer: css({
    paddingTop: '20px',
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '15px 10px',
  }) ,
});

export default FAQStyles;
