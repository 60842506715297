/** @jsxImportSource @emotion/react */
import React from 'react';
import { MessageSection } from '../../../../components/common';

const VisionSection = () => {
    return (
        <MessageSection
            title="展望" 
            message="ダンスや運動を通じて、子供から大人まで、すべての世代に運動の楽しさと価値を伝え、一人ひとりの成長を支える教育を提供します。私たちは、人間的な成長を大切にし、地域の健康と未来を支える活動を続けていきます。" 
            id="vision"
        />
    );
};

export default VisionSection;
