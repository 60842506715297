/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { H1Text, H2Text } from '../../../../components/ui';
import { RotatingText } from '../../../../components/animation';
import headerSectionStyles from './HeaderSectionStyles';

const HeaderSection = () => {
    const theme = useTheme();
    const classes = headerSectionStyles(theme);
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <img
                    src='/images/100TopPage/101HeaderSectionBackGround.png'
                    alt='Background'
                    css={classes.backgroundImage}
                />
                <H1Text css={classes.title}>
                    <span css={classes.highlighted}>R</span>ise <span css={classes.highlighted}>U</span>p{'  '}
                    {isMd && <br />}
                    <RotatingText 
                        css={classes.highlighted}
                        texts={['K', 'K']} 
                        interval={3000} 
                        variant="span" 
                    />
                    <RotatingText 
                        css={classes.rotatingText} 
                        texts={['inetic', ' i d s']} 
                        interval={3000} 
                        variant="span" 
                    />
                    <span css={classes.highlighted}>H</span>ealth
                </H1Text>
                <H2Text size={theme.typography.display} css={classes.fontBold}>ダンスでつなぐ健やかな未来</H2Text>
            </Box>
        </Box>
    );
};

export default HeaderSection;
