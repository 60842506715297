/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, Footer, TitleSection } from '../../components/common';
import { TrialApplicationFormSection } from './components';
import { seoConfig } from '../../config';

const TrialApplication = () => {
    return (
        <>
        <SEO 
            title={seoConfig.trialApplication.title}
            description={seoConfig.trialApplication.description}
            keywords={seoConfig.trialApplication.keywords}
        />
        <header>
            <Menu />
        </header>
        <main>
            <section>
                <TitleSection
                    englishTitle="Trial Application"
                    japaneseTitle="見学会・体験会申込"
                    remarks="※RUKH KID`Sが入ってる施設に限ります"
                />
            </section>
            <section>
                <TrialApplicationFormSection />
            </section>
        </main>
        <footer>
            <Footer />
        </footer>
        </>
    );
};

export default TrialApplication;
