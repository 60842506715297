const hiphopFeaturesData = [
    {
        src: '/images/1100AboutAdultHipHop/1101FeatureImage2.png',
        title: '初心者大歓迎',
        message: 'ダンスが初めての方も大歓迎です！リズム感やステップの基礎をしっかりサポートしますので、自信を持ってご参加いただけます。',
        reverse: false,
    },
    {
        src: '/images/1100AboutAdultHipHop/1101FeatureImage3.png',
        title: 'お子様連れもOK！',
        message: '小さなお子様連れでもご参加いただける環境をご用意しています。お子様を見守りながら、ご自分の時間としてリフレッシュしてみませんか？',
        reverse: true,
    },
    {
        src: '/images/1100AboutAdultHipHop/1101FeatureImage4.png',
        title: '発表会にも参加可能！',
        message: 'ダンスを練習していくうちに、発表会への参加も選べるようになります。皆さんと一緒にステージに立ち、達成感や楽しさを味わいましょう！',
        reverse: false,
    },
]

export default hiphopFeaturesData;
