/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import CustomRadioGroupStyles from './CustomRadioGroupStyles';
import { BodyText } from '../CustomText/CustomText';

const CustomRadioGroup = ({ label, name, value, onChange, options = [], error, helperText }) => {
  const theme = useTheme();
  const classes = CustomRadioGroupStyles(theme);

  return (
    <Box css={classes.formControl}>
      <BodyText size={theme.typography.body} css={classes.label}>
        {label}
      </BodyText>
      <FormControl component="fieldset" error={Boolean(error)} css={classes.radioFormControl}>
        <RadioGroup name={name} value={value} onChange={onChange} css={classes.radioGroup}>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio css={classes.radio}/>}
              label={
                <BodyText size={theme.typography.smallText} css={classes.radioLabel}>{option.label}</BodyText>
              }
            />
          ))}
        </RadioGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default CustomRadioGroup;
