/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import FeaturesSectionStyles from './FeaturesSectionStyles';
import { FeatureTitle, Feature } from './components';
import { featuresData } from '../../../../config';

const FeaturesSection = () => {
    const theme = useTheme();
    const classes = FeaturesSectionStyles(theme);

    return (
        <Box css={classes.container} id='features'>
            <Box css={classes.wrapper}>
                <FeatureTitle
                    title="出張ダンス教室の特徴"
                    subTitle="ダンスを通じて楽しさと感謝を育み、自信と創造性を引き出し、未来に繋がる心と力をサポート"
                    message="私たちは、ダンスを通じて楽しい思い出を未来に繋げ、感謝と思いやりを持つ心を育てたいと考えています。ダンスは自信と自己コントロールを養い、創造性を引き出す手段でもあります。子どもたちが楽しく続けられるよう、サポートしていきます。"
                    image="/images/400ForParents/404FeatureTitleImage.png"
                />
                {featuresData.map((item, index) => (
                    <Feature
                        key={index}
                        title={item.title}
                        message={item.message}
                        image={item.src}
                        reverse={index % 2 === 1}
                    />
                ))}
            </Box>
        </Box>
    )
};

export default FeaturesSection;
