import { css } from '@emotion/react';

const menuStyles = (theme) => ({
  appbar: css({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    padding: '0px',
  }),
  toolbar: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '38px',
    minHeight: '38px !important',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.border.sub}`,
    borderRadius: '5px',
    margin: `10px ${theme.margin.lg}`,
    [theme.breakpoints.down('md')]: {
      margin: `10px ${theme.margin.md}`,
    },
    [theme.breakpoints.down('sm')]: {
      margin: `10px ${theme.margin.sm}`,
    },
  }),
  nav: css({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
    '> li': {
      marginRight: '40px',
      width: 'auto',
    },
  }),
});

export default menuStyles;
