export { default as Menu } from './001Menu/Menu';
export { default as Footer } from './002Footer/Footer';
export { default as ContactSection } from './003ContactSection/ContactSection';
export { default as EventsSection } from './004EventsSection/EventsSection';
export { default as TitleSection } from './005TitleSection/TitleSection';
export { default as InstagramGridSection } from './006InstagramGrid/InstagramGridSection';
export { default as MessageSection } from './007MessageSection/MessageSection';
export { default as BusinessOverviewSection } from './008BusinessOverviewSection/BusinessOverviewSection'
export { default as AchievementsSection } from './009AchievementsSection/AchievmentsSection'
export { default as SEO } from './SEO/SEO';
