import { css } from '@emotion/react';

const TitleSectionStyles = (theme) => ({
    container: css({
        width: '100%',
        padding: '10px 0',
        borderBottom: `1px solid ${theme.palette.divider}`,
    }),
    wrapper: css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        width: '100%',
        maxWidth: theme.width.max,
        margin: `0 auto`,
        padding: `0 ${theme.margin.lg}`,
        [theme.breakpoints.down('md')]: {
            padding: `0 ${theme.margin.md}`,
        },
        [theme.breakpoints.down('sm')]: {
            padding: `0 ${theme.margin.sm}`,
        },
    }),
    englishTitle: css({
        color: theme.palette.text.secondary,
        lineHeight: '1.1 !important',
    }),
    japaneseTitle: css({
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    }),
    remarks: css({
        fontSize: '13px',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    })
});

export default TitleSectionStyles;
