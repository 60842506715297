import { css } from '@emotion/react';

const ConfirmDialogStyles = (theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '600px',
  },
  dialogTitle: {
    backgroundColor: '#bdbdbd',
    color: theme.palette.common.white,
    padding: '20px 30px',
    fontWeight: 'bold',
  },
  dialogContent: {
    padding: '20px 30px',
  },
  dialogContentText: {
    fontSize: theme.typography.bodyLarge.fontSize,
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '30px 0',
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: '20px',
  },
  contentContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 50px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      margin: '10px 0',
    },
  }),
  contact: css({
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 50px',
  }),
  contentText: css({
    fontWeight: 'bold',
  }),
  dialogActions: css({
    justifyContent: 'space-around',
    padding: '40px 0 60px 0',
    '& > *:not(:first-of-type)': {
      marginLeft: 0,
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 0 30px 0',
      justifyContent: 'space-between',
      height: '120px',
    },
  }),
  customButtonSize: 'small',
  customButtonWidth: '155px',
});

export default ConfirmDialogStyles;
