import { css } from '@emotion/react';

const FeaturesSectionStyles = (theme) => ({
    container: css({
        width: '100%',
    }),
    wrapper: css({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: theme.width.max,
        margin: '0 auto',
    }),
});

export default FeaturesSectionStyles;
