/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, ContactSection, EventsSection, Footer, TitleSection } from '../../components/common';
import { Background, FeaturesSection, HipHopCard } from './components';
import { seoConfig } from '../../config';

const AboutAdultHipHop = () => {
    return (
        <>
        <SEO 
            title={seoConfig.aboutAdultHipHop.title}
            description={seoConfig.aboutAdultHipHop.description}
            keywords={seoConfig.aboutAdultHipHop.keywords}
        />
        <header>
            <Menu />
        </header>
        <main>
            <section>
                    <TitleSection englishTitle="About Adult HIPHOP" japaneseTitle="大人のヒップホップについて" />
            </section>
            <Background>
                <section>
                    <FeaturesSection />
                </section>
                <section>
                    <HipHopCard />
                </section>
                <section>
                    <EventsSection />
                </section>
                <section>
                    <ContactSection />
                </section>
            </Background>
        </main>
        <footer>
            <Footer />
        </footer>
        </>
    );
};

export default AboutAdultHipHop;
