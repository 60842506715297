/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, ContactSection, EventsSection, Footer, InstagramGridSection } from '../../components/common';
import { HeaderSection, AboutUsSection, ContentsSection, TestimonialsSection, Background } from '../100TopPage/components';
import { seoConfig } from '../../config';

const TopPage = () => {
  return (
    <>
      <SEO 
        title={seoConfig.home.title}
        description={seoConfig.home.description}
        keywords={seoConfig.home.keywords}
      />
      <header>
        <Menu />
      </header>
      <main>
        <section>
          <HeaderSection />
        </section>
        <section>
          <AboutUsSection />
        </section>
        <Background>
          <section>
            <InstagramGridSection />
          </section>
          <section>
            <ContentsSection />
          </section>
          <section>
            <TestimonialsSection />
          </section>
          <section>
            <EventsSection />
          </section>
          <section>
            <ContactSection />
          </section>
        </Background>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default TopPage;
