import { css } from '@emotion/react';

const FooterStyles = (theme) => ({
    footer: css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '50px 48px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
            padding: '30px 30px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '20px 16px',
        },
    }),
    logoContainer: css({
        width: '100%',
        maxWidth: theme.width.max,
    }),
    logo: {
        width: '100%',
        height: 'auto',
    },
    separator: css({
        width: '100%',
        maxWidth: theme.width.max,
        borderBottom: `1px solid ${theme.palette.common.white}`,
        marginBottom: '20px',
    }),
});

export default FooterStyles;
