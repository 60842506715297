import { css } from '@emotion/react';

const CustomRadioGroupStyles = (theme) => ({
    formControl: css({
        display: 'flex',
        width: '100%',
        marginBottom: '16px',
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    }),
    label: css({
        flexBasis: '30%',
        textAlign: 'left',
        marginRight: 16,
        fontWeight: 'bold',
    }),
    radioGroup: css({
        flexBasis: '70%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '4px',
        marginLeft: '5px',
    }),
    radio : css({
        padding: '0px 1px 0px 0px',
    }),
    radioLabel: css({
        letterSpacing: '0.5px',
    }),
});

export default CustomRadioGroupStyles;
