/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import FeaturesSectionStyles from './FeaturesSectionStyles';
import { FeatureTitle, Feature } from './components';
import { hiphopFeaturesData } from '../../../../config';

const FeaturesSection = () => {
    const theme = useTheme();
    const classes = FeaturesSectionStyles(theme);

    return (
        <Box css={classes.container} id='features'>
            <Box css={classes.wrapper}>
                <FeatureTitle
                    title="大人のヒップホップ教室の特徴"
                    subTitle="ダンスを通じて楽しさと自信を引き出し、新しい自分に出会うサポートをします"
                    message="ダンスを始めたい大人の方へ。リズムに乗って楽しく体を動かしながら、自信と自己表現力を育みましょう。新しい挑戦を始めたい方、ぜひご参加ください！"
                    image="/images/1100AboutAdultHipHop/1101FeatureImage1.png"
                />
                {hiphopFeaturesData.map((item, index) => (
                    <Feature
                        key={index}
                        title={item.title}
                        message={item.message}
                        image={item.src}
                        reverse={index % 2 === 1}
                    />
                ))}
            </Box>
        </Box>
    )
};

export default FeaturesSection;
