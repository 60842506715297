const solutions = [
    {
        point: 'POINT 1',
        title: 'ダンスで総合的に身体能力を上げる',
        description: '運動不足が心配な子供たちには、ダンスを通じて楽しみながら運動させることで、リズム感やバランス感覚を養い、総合的な身体能力を向上させることができます。これは、現代の子供たちの健康維持に効果的です。',
        imgSrc: '/images/800Business/804SolutionSection1.png',
    },
    {
        point: 'POINT 2',
        title: '幼稚園での習い事で時間の節約',
        description: '忙しい保護者の方々にとって、子供を別の場所に送る時間がないという悩みを解決します。幼稚園のスケジュールに組み込まれたダンスレッスンにより、手間をかけずに子供の習い事ができます。',
        imgSrc: '/images/800Business/804SolutionSection2.png',
    },
    {
        point: 'POINT 3',
        title: '福利厚生としてのダンスプログラムの導入',
        description: '福利厚生を充実させたい幼稚園には、ダンスプログラムを提供することで、職員や子供たちの健康促進とコミュニケーション向上に貢献します。これにより、幼稚園の職場環境や教育環境がより魅力的になります。',
        imgSrc: '/images/800Business/804SolutionSection3.png',
    }
];

export default solutions;
