import { useState } from 'react';
import axios from 'axios';
import { urls } from '../../../../../../config';
import { useGetCSRFToken } from '../../../../../../utils';

const UseApplicationForm = () => {
  const [state, setState] = useState({
    '教室の見学': false,
    'プログラム体験': false,
    'まずは相談したい': false,
    affiliation: '',
    desiredTime: '',
    childGrade: '',
    parentName: '',
    parentKana: '',
    phoneNumber: '',
    email: '',
    confirmEmail: '',
    preContact: '',
    postalCode: '',
    address: '',
    source: '',
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState('');
  const [open, setOpen] = useState(false);
  const { csrfToken, loading } = useGetCSRFToken();
  const [submitting, setSubmitting] = useState(false);

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    // 必須チェックとラジオボタンの選択チェック
    if (!state.教室の見学 && !state.プログラム体験 && !state.まずは相談したい) {
      tempErrors.requestContent = "ご希望内容を選択してください";
      isValid = false;
    }

    if (!state.affiliation) {
      tempErrors.affiliation = "所属施設を入力してください";
      isValid = false;
    }

    if (!state.desiredTime) {
      tempErrors.desiredTime = "ご利用時期のご希望を選択してください";
      isValid = false;
    }

    if (!state.childGrade) {
      tempErrors.childGrade = "お子様の学年を選択してください";
      isValid = false;
    }

    // 名前のバリデーション
    if (!state.parentName) {
      tempErrors.parentName = "保護者様のお名前を入力してください";
      isValid = false;
    }

    if (!state.parentKana) {
      tempErrors.parentKana = "保護者様のふりがなを入力してください";
      isValid = false;
    }

    // 電話番号のバリデーション
    if (!state.phoneNumber) {
      tempErrors.phoneNumber = "電話番号を入力してください";
      isValid = false;
    } else if (!/^\d{10,11}$/.test(state.phoneNumber)) {
      tempErrors.phoneNumber = "有効な電話番号を入力してください";
      isValid = false;
    }

    // メールアドレスのバリデーション
    if (!state.email) {
      tempErrors.email = "メールアドレスを入力してください";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(state.email)) {
      tempErrors.email = "有効なメールアドレスを入力してください";
      isValid = false;
    }

    if (!state.confirmEmail) {
      tempErrors.confirmEmail = "確認用のメールアドレスを入力してください";
      isValid = false;
    } else if (state.email !== state.confirmEmail) {
      tempErrors.confirmEmail = "メールアドレスが一致しません";
      isValid = false;
    }

    if (!state.preContact) {
      tempErrors.preContact = "優先連絡手段を選択してください";
      isValid = false;
    }

    // 住所のバリデーション
    if (!state.postalCode) {
      tempErrors.postalCode = "郵便番号を入力してください";
      isValid = false;
    } else if (!/^\d{7}$/.test(state.postalCode)) {
      tempErrors.postalCode = "有効な郵便番号を入力してください";
      isValid = false;
    }

    if (!state.address) {
      tempErrors.address = "住所を入力してください";
      isValid = false;
    }

    if (!state.agree) {
      tempErrors.agree = true;
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;
    if (loading) {
      console.log('CSRF token is loading...');
      return;
    }
    if (!csrfToken) {
      console.error('No CSRF token available');
      return;
    }

    setSubmitting(true);

    try {
      const response = await axios.post(
        `${urls.api}/web/send-trial-application-gmail/`,
        state,
        {
          headers: {
            'X-CSRFToken': csrfToken,  // CSRFトークンをヘッダーに追加
            'Content-Type': 'application/json',
          }
        }
      );
      setStatus(response.data.status);
      setState({  // 成功時にフォームをクリア
        '教室の見学': false,
        'プログラム体験': false,
        'まずは相談したい': false,
        affiliation: '',
        desiredTime: '',
        childGrade: '',
        parentName: '',
        parentKana: '',
        phoneNumber: '',
        email: '',
        confirmEmail: '',
        preContact: '',
        postalCode: '',
        address: '',
        source: '',
      });
    } catch (error) {
      setStatus('Error sending application');
    } finally {
      setSubmitting(false);
    }
  }


  const handleDialogOpen = () => {
    if (validateForm()) {
      setOpen(true);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDialogSubmit = () => {
    handleSubmit();
    setOpen(false);
  };

  return {
    state,
    errors,
    status,
    open,
    submitting,
    handleChange,
    handleDialogOpen,
    handleDialogClose,
    handleDialogSubmit,
  };
};

export default UseApplicationForm;
