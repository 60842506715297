/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { H3Text } from '../../../../ui';
import AchievmentContentStyles from './AchievmentContentStyles';

const AchievmentContent = ({ src, title, alt }) => {
    const theme = useTheme();
    const classes = AchievmentContentStyles(theme);

    return (
        <Box css={classes.container}>
            <H3Text size={theme.typography.bodyLarge} css={classes.title}>{title}</H3Text>
            <img src={src} alt={alt} css={classes.challegeImage} />
        </Box>
    );
};

export default AchievmentContent;
