const seoConfig = {
    home: {
        title: "RUKH | ダンス教室",
        description: "RUKHは名古屋市守山区でキッズダンスを提供するダンス教室です。初心者から経験者まで、すべての年齢層に合わせた楽しいレッスンを開催しています。",
        keywords: "ダンス教室, キッズダンス, 大人のヒップホップ教室, 大人のヒップホップ, 初心者, 名古屋, 名古屋市守山区, 子供向けダンス, ダンスレッスン, RUKH, 習い事, 子供の習い事",
    },
    aboutUs: {
        title: "About Us | 企業理念、MVV、代表挨拶、経歴" ,
        description: "合同会社RUKHの企業理念、MVV（ミッション、ビジョン、バリュー）、代表挨拶、経歴について説明するページです。" ,
        keywords: "合同会社RUKH, 企業理念, MVV, 代表挨拶, 経歴, ミッション, ビジョン, バリュー",
    },
    aboutTravelingDanceClasses: {
        title: "出張ダンス教室について" ,
        description: "RUKH KID'Sの特徴をダンスのメリットとRUKHのメリットの二つに分けて説明するページです。" ,
        keywords: "RUKH KID'S, ダンス教室, キッズダンス, 子供の習い事, 幼稚園, 保育園, 心身の成長, コミュニケーション力, ダンスのメリット, 自信を育てる"
    },
    contact: {
        title: "お問い合わせ | RUKH - キッズダンス教室へのご質問はこちら" ,
        description: "RUKHのキッズダンス教室についてのご質問やご相談はこちらのお問い合わせページからお送りください。名古屋市守山区で開催するレッスンの詳細についてもお問い合わせいただけます。" ,
        keywords: "RUKH, お問い合わせ, キッズダンス, ダンス教室, 名古屋, 守山区, ご相談, ご質問, ダンスレッスン"
    },
    trialApplication: {
        title: "見学会・体験会申込" ,
        description: "RUKHの見学会・体験会への申し込みページです。見学会や体験会を通じて、RUKHのダンス教室の魅力を体感してください。" ,
        keywords: "RUKH, 見学会, 体験会, ダンス教室, キッズダンス, 名古屋, 習い事, 見学申込, 体験申込, 子供ダンス"
    },
    aboutRukhFit: {
        title: "RUKH | 幼稚園やフィットネス、シニア向けの訪問指導" ,
        description: "RUKHでは、幼稚園や保育園での訪問指導、フィットネスや陸上教室での指導サポート、そして高齢者向けの運動指導を提供しています。活動実績や事業内容についても掲載しています。" ,
        keywords: "RUKH, RUKH FIT, 大人のヒップホップ, 大人のヒップホップ教室, フィットネス, 初心者, 陸上教室, 高齢者, ボランティア, 健康促進プログラム, 運動指導, 訪問指導"
    },
    privacyPolicy: {
        title: "RUKH | プライバシーポリシー" ,
        description: "RUKHのプライバシーポリシーです。ユーザーの個人情報の取扱いについて、当社が収集する情報、利用目的、第三者への提供、画像の無断使用防止に関する方針を定めています。" ,
        keywords: "RUKH, プライバシーポリシー, 個人情報保護, 画像の無断使用, 個人情報の取り扱い, 問い合わせフォーム, 体験会申込フォーム, 個人情報提供"
    },
    rukhKids: {
        title: "RUKH | 幼稚園やフィットネス、シニア向けの訪問指導" ,
        description: "RUKHでは、幼稚園や保育園での訪問指導、フィットネスや陸上教室での指導サポート、そして高齢者向けの運動指導を提供しています。活動実績や事業内容についても掲載しています。" ,
        keywords: "RUKH, 幼稚園, 保育園, フィットネス, 陸上教室, 高齢者, ボランティア, 健康促進プログラム, 運動指導, 訪問指導"
    },
    aboutAdultHipHop: {
        title: "大人のヒップホップ教室について" ,
        description: "RUKH FITの大人のヒップホップ教室について説明しているページです。",
        keywords: "RUKH FIT, 大人のヒップホップ教室, 大人のヒップホップ, 初心者, HIPHOP, 大人のHIPHOP教室, 習い事, 大人の習い事"
    },
    benefitDance: {
        title: "幼児のためのダンスのメリット | RUKH - 子どもの成長を支えるダンス教室",
        description: "幼児向けダンスがもたらすメリットについて紹介します。ダンスを通じて、リズム感や運動能力の向上、自己表現力、協調性が自然と育まれ、心と体の成長を支えます。RUKHでは、子どもが楽しみながら成長できる環境を提供しています。",
        keywords: "幼児ダンス, ダンスのメリット, リズム感, 運動能力, 自己表現, 協調性, 幼児教育, 名古屋, 子どもの成長, RUKH"
    }
};

export default seoConfig;
