import { css } from '@emotion/react';

const BusinessOverviewSectionStyles = (theme, reverse) => ({
    titleContainer: css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        margin: '0 auto',
        height: 'auto',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        padding: '10px 0',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    }),
    container: css({
        width: '100%',
        [theme.breakpoints.down('md')]: {
            padding: '40px',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '30px',
        },
    }),
    wrapper: css({
        width: '100%',
        maxWidth: theme.width.max,
        display: 'flex',
        flexDirection: reverse ? 'row-reverse' : 'row',  // 位置を反転する
        margin: '0 auto',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            borderRadius: '50px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
    }),
    leftContainerStyles : css({
        flex: '3',
        padding: '60px 20px 60px 48px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    }),
    title: css({
        marginBottom: '50px',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            marginBottom: '40px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '30px',
        },
    }),
    subTitle : css({
        marginBottom: '40px',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            marginBottom: '30px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
        },
    }),
    description : css({
        fontWeight: 'bold',
    }),
    rightContainerStyles : css({
        flex: '1.5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '-1',
        },
    }),
    imageStyles : css({
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '8px',
        [theme.breakpoints.down('md')]: {
            content: `url('/images/800Business/802OverviewSectionImageMedium.png')`,
        },
        [theme.breakpoints.down('sm')]: {
            content: `url('/images/800Business/802OverviewSectionImageSmall.png')`,
        },
    }),
});

export default BusinessOverviewSectionStyles;
