/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import Testimonial from '../Testimonial/Testimonial';
import CarouselStyles from './CarouselStyles';

const Carousel = ({ handleTransitionEnd, visibleItems, slideStyle }) => {
    const theme = useTheme();
    const classes = CarouselStyles(theme);

    return (
        <Box css={classes.carousel}>
            <Box
                css={classes.slider(slideStyle)}
                onTransitionEnd={handleTransitionEnd}
            >
                {visibleItems.map((testimonial, index) => (
                    <Box key={index} css={classes.listItem}>
                        <Testimonial {...testimonial} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default Carousel;
