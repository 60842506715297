/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box } from '@mui/material';
import { BodyText } from '../../../../components/ui';
import ApplicationForm from './components/ApplicationForm/ApplicationForm';
import TrialApplicationFormSectionStyles from './TrialApplicationFormSectionStyles';

const TrialApplicationFormSection = () => {
  const theme = useTheme();
  const classes = TrialApplicationFormSectionStyles(theme);

  return (
    <Box css={classes.container}>
      <Box css={classes.wrapper}>
        <BodyText size={theme.typography.body} css={classes.title}>
          お問い合わせを入力してください。
          <br />
          *は必須入力です。必ず入力してください。
        </BodyText>
        <ApplicationForm />
      </Box>
    </Box>
  );
};

export default TrialApplicationFormSection;
