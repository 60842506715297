import urls from './urls';

const navItems = [
    {
        title: 'RUKH KID`S',
        url: urls.aboutRukhKids,
        subItems: [
            { text: '出張ダンス教室', url: `${urls.aboutTravelingDanceClasses}` },
            { text: 'ダンスのメリット', url: `${urls.benefitDance}` },
        ]
    },
    {
        title: 'RUKH FIT',
        url: urls.aboutRukhFit,
        subItems: [
            { text: '大人のヒップホップ', url: `${urls.aboutAdultHipHop}` },
        ]
    },
    {
        title: 'RUKHについて',
        url: urls.aboutUs,
        subItems: [
            { text: '企業理念', url: `${urls.aboutUs}#philosophy` },
            { text: 'ご挨拶', url: `${urls.aboutUs}#greetings` },
        ]
    },
    {
        title: 'お問い合わせ',
        url: urls.contact,
        subItems: [
            { text: 'お問い合わせ', url: urls.contact },
            { text: '見学・体験会', url: urls.trial },
        ]
    }
];

export default navItems;
