import { css } from '@emotion/react';

const AchievmentContentStyles = (theme) => ({
    container: css({
        width: '30%',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }),
    challegeImage: css({
        width: '100%',
        marginBottom: '16px',
        borderRadius: '10px',
    }),
    title: css({
        display: 'flex',
        textAlign: 'left',
        fontWeight: 'bold',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
            fontSize: '20px',
        },
    }),
    description: css({
        textAlign: 'left',
    }),
});

export default AchievmentContentStyles;
