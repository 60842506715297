/** @jsxImportSource @emotion/react */
import React from 'react';
import { SEO, Menu, Footer, TitleSection, EventsSection, ContactSection, AchievementsSection, BusinessOverviewSection } from '../../components/common';
import { Background, BusinessSection2 } from './components';
import { achievmentData, seoConfig } from '../../config';

const AboutRukhFit = () => {
    return (
        <>
        <SEO 
            title={seoConfig.aboutRukhFit.title}
            description={seoConfig.aboutRukhFit.description}
            keywords={seoConfig.aboutRukhFit.keywords}
        />
        <header>
            <Menu />
        </header>
        <main>
            <section>
                    <TitleSection englishTitle="About RUKH FIT" japaneseTitle="RUKH FITについて" />
            </section>
            <section>
                <BusinessOverviewSection
                    id="rukh-fit"
                    title="RUKH FIT"
                    main="すべての世代に活力と健康を届ける場"
                    sub="心と体を無理なく整え、健康的でアクティブな生活を楽しむための運動プログラムを提供します。"
                    body="RUKH FITは、すべての世代の人々が健康で充実した生活を送れるよう、体に負担をかけずに続けられる運動を提供しています。私たちは、心と体のバランスを整え、毎日の生活をより豊かにすることを大切にしています。どんな年齢でも、自分の体と向き合い、前向きに生きる力を引き出すために、健康の土台を築くサポートをしています。"
                    src="/images/800Business/802OverviewSectionImage2.png"
                    alt="overviewSectionImage"
                    reverse={true}
                />
            </section>
            <Background>
                <section>
                    <BusinessSection2 />
                    <AchievementsSection achievmentData={achievmentData.business2} />
                </section>
                <section>
                    <EventsSection />
                </section>
                <secttion>
                    <ContactSection />
                </secttion>
            </Background>
        </main>
        <footer>
            <Footer />
        </footer>
        </>
    );
};

export default AboutRukhFit;
