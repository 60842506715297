/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BodyText, H2Text, H3Text, H4Text } from '../../ui';
import BusinessOverviewSectionStyles from './BusinessOverviewSectionStyles';

const BusinessOverviewSection = ({ id, title, main, sub, body, src, alt, reverse = false }) => {
    const theme = useTheme();
    const classes = BusinessOverviewSectionStyles(theme, reverse);

    return (
        <>
        <Box css={classes.titleContainer} id={id}>
                <H2Text size={theme.typography.title}>{title}</H2Text>
        </Box>
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
            <Box css={classes.leftContainerStyles}>
                <H3Text size={theme.typography.title} css={classes.title}>
                    {main}
                </H3Text>
                <H4Text size={theme.typography.titleSmall} css={classes.subTitle}>
                    {sub}
                </H4Text>
                <BodyText size={theme.typography.bodyLarge} css={classes.description}>
                    {body}
                </BodyText>
            </Box>
            <Box css={classes.rightContainerStyles}>
                <img 
                    src={src} 
                    alt={alt}
                    css={classes.imageStyles} 
                />
            </Box>
            </Box>
        </Box>
        </>
    );
};

export default BusinessOverviewSection;
