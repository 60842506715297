import { css } from '@emotion/react';

const HipHopCardStyles = (theme) => ({
    container: css({
        display: 'flex',
        justifyContent: 'center',
        padding: '40px 20px',
        width: '100%',
        opacity: '0.8',
    }),
    card: css({
        maxWidth: '600px',
        width: '100%',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: theme.palette.common.white,
        textAlign: 'center',
    }),
    title: css({
        paddingBottom: '10px',
        marginBottom: '20px',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    }),
    info: css({
        marginBottom: '15px',
        paddingLeft: '20px',
        textAlign: 'left',
    }),
    text: css({
        fontWeight: 'bold',
    }),
    button: css({
        marginTop: '20px',
        width: '100%',
        padding: '10px 0',
        display: 'flex',
        justifyContent: 'center',
    }),
    buttonMinWidth: '300px',
});

export default HipHopCardStyles;
