import { css } from '@emotion/react';

const MessageSectionStyles = (theme) => ({
    container: css({
      width: '100%',
      padding: '15px 20px',
      [theme.breakpoints.down('sm')]: {
        padding: '5px 10px',
      },
    }),
    wrapper: css({
      maxWidth: '1000px',
      margin: '0 auto',
      textAlign: 'left',
      padding: '20px',
      display: 'flex',
      flexDirection: 'row', // 横並びに設定
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
          flexDirection: 'column',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        padding: '10px',
      },
    }),
    title: css({
      color: theme.palette.primary.main,
      marginBottom: '16px',
      fontWeight: 'bold',
      paddingBottom: '8px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '5px',
        paddingBottom: '0px',
      }
    }),
    message: css({
      maxWidth: '600px',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    }),
});

export default MessageSectionStyles;
