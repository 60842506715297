/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import { BackgroundCircle } from '../../../../components/ui';

const Background = ({ children }) => {
    const theme = useTheme();

    return (
        <Box css={containerStyle}>
            <Box css={wrapperStyle(theme)}>
                <BackgroundCircle size="100px" colors={[theme.palette.circle.secondary.top, theme.palette.circle.secondary.bottom]} position={{ top: '5%', right: '10%' }} />
                <BackgroundCircle size="250px" colors={[theme.palette.circle.primary.top, theme.palette.circle.primary.bottom]} position={{ top: '10%', left: '5%' }} />
                <BackgroundCircle size="500px" colors={[theme.palette.circle.secondary.top, theme.palette.circle.secondary.bottom]} position={{ top: '20%', right: '0%' }} />
                <BackgroundCircle size="100px" colors={[theme.palette.circle.primary.top, theme.palette.circle.primary.bottom]} position={{ top: '30%', left: '50%' }} />
                <BackgroundCircle size="100px" colors={[theme.palette.circle.primary.top, theme.palette.circle.primary.bottom]} position={{ top: '40%', left: '10%' }} />
                <BackgroundCircle size="90px" colors={[theme.palette.circle.secondary.top, theme.palette.circle.secondary.bottom]} position={{ top: '42%', right: '50%' }} />
                <BackgroundCircle size="200px" colors={[theme.palette.circle.primary.top, theme.palette.circle.primary.bottom]} position={{ top: '50%', right: '15%' }} />
                <BackgroundCircle size="300px" colors={[theme.palette.circle.primary.top, theme.palette.circle.primary.bottom]} position={{ top: '50%', left: '20%' }} />
                <BackgroundCircle size="330px" colors={[theme.palette.circle.secondary.top, theme.palette.circle.secondary.bottom]} position={{ top: '65%', left: '20%' }} />
                <BackgroundCircle size="150px" colors={[theme.palette.circle.primary.top, theme.palette.circle.primary.bottom]} position={{ top: '70%', right: '20%' }} />
                <BackgroundCircle size="450px" colors={[theme.palette.circle.primary.top, theme.palette.circle.primary.bottom]} position={{ top: '90%', right: '15%' }} />

                {children}
            </Box>
        </Box>
    );
};

// スタイル設定
const containerStyle = css({
    overflow: 'hidden',
    width: '100%',
});

const wrapperStyle = (theme) => css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: theme.width.max,
    margin: '0 auto',
});

export default Background;
