/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { West, East } from '@mui/icons-material';
import { H2Text } from '../../../../../../components/ui';
import TitleWithButtonStyles from './TitleWithButtonsStyles';

const TitleWithButtons = ({ handlePrevButton, handleNextButton }) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const classes = TitleWithButtonStyles(theme);

    return (
        <Box css={classes.titleBox}>
            <H2Text size={theme.typography.titleLarge} css={classes.title}>
                ご利用者様の声
            </H2Text>
            {!isMdDown && (
                <Box css={classes.iconBox}>
                    <IconButton css={classes.button} onClick={handlePrevButton}>
                        <West />
                    </IconButton>
                    <IconButton css={classes.button} onClick={handleNextButton}>
                        <East />
                    </IconButton>
                </Box>
            )}
            {isMdDown && (
                <>
                    <IconButton css={[classes.leftButton, classes.button]} onClick={handlePrevButton}>
                        <West />
                    </IconButton>
                    <IconButton css={[classes.rightButton, classes.button]} onClick={handleNextButton}>
                        <East />
                    </IconButton>
                </>
            )}
        </Box>
    );
};

export default TitleWithButtons;
