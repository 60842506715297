/** @jsxImportSource @emotion/react */
import React from 'react';
import { Menu, SEO, TitleSection, InstagramGridSection } from '../../components/common';

const ExamplePage = () => {
  return (
    <div>
      <SEO title="Top Page" description="これはトップページです。" />
      <Menu />
      <TitleSection englishTitle="For Parents" japaneseTitle="保護者の皆様へ" />
      <InstagramGridSection />
    </div>
  );
};

export default ExamplePage;
