import { css } from '@emotion/react';

const BenefitStyles = (theme, reverse) => ({
  container: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '100px',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  }),
  imageContainer: css({
    flex: '0 0 40%',
    order: reverse ? 2 : 1,
    marginLeft: reverse ? '-40px' : '0',
    marginRight: reverse ? '0' : '-40px',
    zIndex: -1,
    [theme.breakpoints.down('md')]: {
      order: 1,
      margin: '0',
      width: '80%',
    },
  }),
  image: css({
    width: '100%',
    aspectRatio: '3 / 2',
    borderRadius: '120px',
  }),
  contentContainer: css({
    flex: '1',
    order: reverse ? 1 : 2,
    [theme.breakpoints.down('md')]: {
      order: 2,
      padding: '20px 0',
    },
  }),
  title: css({
    fontWeight: 'bold',
    paddingLeft: reverse ? '20px' : '60px',
    paddingRight: reverse ? '60px' : '20px',
    marginBottom: '10px',
    paddingBottom: '10px',
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
      textAlign: 'center',
    },
  }),
  message: css({
    fontWeight: 'bold',
    paddingLeft: reverse ? '20px' : '60px',
    paddingRight: reverse ? '60px' : '20px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  }),
});

export default BenefitStyles;
