/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme  } from '@mui/material';
import { BodyText, H2Text } from '../../../../components/ui';
import GreetingSectionStyles from './GreetingSectionStyles';

const GreetingSection = () => {
    const theme = useTheme();
    const classes = GreetingSectionStyles(theme);

    return (
        <Box css={classes.container} id='greetings'>
            <Box css={classes.wrapper}>
                <H2Text size={theme.typography.titleLarge} css={classes.title}>ご挨拶</H2Text>
                <Box css={classes.contents}>
                    <Box css={classes.imageContainer}>
                        <img src="/images/200About/Greeting.png" alt="Greeting" css={classes.greetingImage} />
                    </Box>
                    <Box css={classes.messageContainer}>
                        <BodyText size={theme.typography.bodyLarge} css={classes.greetingMessage}>
                            2017年より幼稚園に出向き、実際にダンスクラスを実施して参りました。これまでの経験やノウハウを生かし、さらに多くの幼稚園や保育園で活動の場を広げるため、合同会社RUKHを設立いたしました。子供たちの健やかな成長と笑顔を支えるため、これからも全力で取り組んでまいります。未来に向けてさらなる成長と挑戦を続け、皆様と共に歩んでまいりたいと思います。
                        </BodyText>
                        <BodyText size={theme.typography.bodyLarge} css={classes.name}>
                            篠田　かほる
                        </BodyText>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default GreetingSection;
