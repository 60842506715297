import { css } from '@emotion/react';

const TestimonialsSectionStyles = (theme) => {
    return {
        container: css({
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative',
            height: 'auto',
        }),
    }
}

export default TestimonialsSectionStyles;
