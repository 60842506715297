import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  margin: {
    lg: '48px',
    md: '30px',
    sm: '16px',
    top: '58px', // Appbar height
    bar: '48px', // Appbar height - Appbar margin bottom
  },
  width: {
    max: '1320px',
  },
  aspectRatio: {
    mobile: '3 / 5',
    tablet: '5 / 7',
  },
  palette: {
    primary: {
      main: '#F58220',
      sub: '#FCDBBF',
    },
    secondary: {
      main: '#40D219',
    },
    white: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#545454',
      light: '#F0F0F0',
    },
    border: {
      sub: '#C8C8C8',
    },
    circle: {
      primary: {
        top: '#FCDBBF',
        bottom: '#F58220',
      },
      secondary: {
        top: '#C8F2BD',
        bottom: '#40D219',
      }
    }
  },
  typography: {
    fontFamily: [
      'Handel Gothic',
      'Yu Gothic',
      'Roboto',
      'Arial',
      'sans-serif'
    ].join(','),
    smallText: {
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '2px',
    },
    bodySmall: {
      fontSize: '15px',
      lineHeight: '24px',
      letterSpacing: '1.5px',
      '@media (max-width:600px)': {
        fontSize: '12px',
      },
    },
    body: {
      fontSize: '17px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      '@media (max-width:900px)': {
        fontSize: '15px',
        lineHeight: '20px',
      },
      '@media (max-width:600px)': {
        fontSize: '13px',
        lineHeight: '15px',
      },
    },
    bodyLarge: {
      fontSize: '19px',
      lineHeight: '40px',
      letterSpacing: '0.5px',
      '@media (max-width:900px)': {
        fontSize: '17px',
        lineHeight: '34px',
      },
      '@media (max-width:600px)': {
        fontSize: '15px',
        lineHeight: '28px',
      },
    },
    titleSmall: {
      fontSize: '25px',
      lineHeight: '32px',
      letterSpacing: '0.5px',
      '@media (max-width:900px)': {
        fontSize: '22px',
        lineHeight: '24px',
      },
      '@media (max-width:600px)': {
        fontSize: '18px',
        lineHeight: '19px',
      },
    },
    title: {
      fontSize: '31px',
      lineHeight: '40px',
      letterSpacing: '0.5px',
    },
    titleLarge: {
      fontSize: '43px',
      lineHeight: '48px',
      letterSpacing: '0.5px',
      '@media (max-width:900px)': {
        fontSize: '37px',
      },
      '@media (max-width:600px)': {
        fontSize: '33px',
      },
    },
    display: {
      fontSize: '49px',
      lineHeight: '56px',
      letterSpacing: '0.5px',
      '@media (max-width:900px)': {
        fontSize: '33px',
        lineHeight: '40px',
      },
      '@media (max-width:600px)': {
        fontSize: '22px',
        lineHeight: '28px',
      },
    },
    invisible: {
      position: 'absolute',
      left: '-9999px',
    }
  },
});

export default theme;
