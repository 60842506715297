/** @jsxImportSource @emotion/react */
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTheme, Box, Card, CardContent, IconButton } from '@mui/material';
import useFAQ from './useFAQ';
import FAQStyles from './FAQStyles';
import { BodyText, H3Text, H4Text } from '../../../../../../components/ui';

function FAQ({ question, answer, supplement }) {
    const theme = useTheme();
    const classes = FAQStyles(theme);
    const { isOpen, toggleFAQ } = useFAQ();

    return (
        <Card css={classes.faqCard}>
        <CardContent css={classes.cardContent}>
            <Box css={classes.faqQuestion} onClick={toggleFAQ}>
                <Box css={classes.faqTitle}>
                    <H3Text size={theme.typography.titleSmall} css={classes.q}>Q</H3Text>
                    <H3Text size={theme.typography.bodyLarge}>{question}</H3Text>
                </Box>
                <IconButton>
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            {isOpen && (
            <Box css={classes.faqAnswer}>
                <H4Text size={theme.typography.bodyLarge} css={classes.answer}>{answer}</H4Text>
                <BodyText size={theme.typography.bodySmall}>{supplement}</BodyText>
            </Box>
            )}
        </CardContent>
        </Card>
    );
}

export default FAQ;
