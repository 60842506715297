import { css } from '@emotion/react';

const ContactSectionStyles = (theme, isMobile) => ({
    container: css({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '100px 0 150px 0',
    }),
    wrapper: css({
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        maxWidth: '1000px',
        height: 'auto',
        padding: '10px 100px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '30px',
        boxShadow: theme.shadows[1],
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
        }
    }),
    title: css({
        fontSize: '25px',
        padding: '10px 0 15px 0',
        fontWeight: 'bold',
    }),
    message: css({
        marginBottom: '20px',
        textAlign: 'center',
    }),
    image: css({
        position: 'absolute',
        bottom: '0',
        left: '0',
        width: '100px',
        height: '120px',
        [theme.breakpoints.down('sm')]: {
            bottom: 'auto',
            top: '-70px',
            left: '5px',
            width: '60px',
            height: '70px',
            zIndex: '-1',
        },
    }),
    buttonSize: isMobile ? 'small' : 'medium',
    buttonWidth: '60%',
    buttonMinWidth: '235px',
    bodyText: {
        fontSize: isMobile ? '11px' : '14px',
    },
});

export default ContactSectionStyles;
