/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BodyText, H2Text } from '../../../../components/ui';
import ChallengesSectionStyles from './ChallengesSectionStyles';
import { challengesData } from '../../../../config';
import { ChallengeContent } from './components';

const ChallengesSection = () => {
    const theme = useTheme();
    const classes = ChallengesSectionStyles(theme);

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <H2Text size={theme.typography.title} css={classes.title}>こんなことにお困りではないですか？</H2Text>
                <BodyText size={theme.typography.body} css={classes.description}>
                    保護者の皆様、幼稚園の関係者様、どんなことでお悩みではないですか？<br />
                    以下のようなことがあるのではないでしょうか？
                </BodyText>
                <Box css={classes.contents}>
                    {challengesData.map((challange, index) => (
                    <ChallengeContent
                        key={index}
                        src={challange.src}
                        title={challange.title}
                        description={challange.message}
                    />
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default ChallengesSection;
