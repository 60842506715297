  const introductionImages = [
    { src: '/images/400ForParents/402IntroductionImage1.png', aspectRatio: '16/9' },
    { src: '/images/400ForParents/402IntroductionImage2.png', aspectRatio: '1/1' },
    { src: '/images/400ForParents/402IntroductionImage3.png', aspectRatio: '16/9' },
    { src: '/images/400ForParents/402IntroductionImage4.png', aspectRatio: '16/9' },
    { src: '/images/400ForParents/402IntroductionImage5.png', aspectRatio: '16/9' },
    { src: '/images/400ForParents/402IntroductionImage6.png', aspectRatio: '16/9' },
  ];

  export default introductionImages;
