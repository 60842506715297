/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ConfirmDialogStyles from './ConfirmDialogStyles';
import { BodyText, CustomButton } from '../../../../../../components/ui';

const ConfirmDialog = ({ formData, open, handleDialogClose, handleDialogSubmit }) => {
  const theme = useTheme();
  const classes = ConfirmDialogStyles(theme);

  return (
    <Dialog 
      open={open} 
      onClose={handleDialogClose} 
      PaperProps={{ sx: classes.dialog }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={classes.dialogTitle}>
        入力内容の確認
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <DialogContentText sx={classes.dialogContentText}>
          以下の内容で送信します。よろしいですか？
        </DialogContentText>

        {/* お名前 */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>お名前：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.parentName}</BodyText>
        </Box>

        {/* ふりがな */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>ふりがな：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.parentKana}</BodyText>
        </Box>

        {/* 電話番号 */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>電話番号：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.phoneNumber}</BodyText>
        </Box>

        {/* メールアドレス */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>メールアドレス：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.email}</BodyText>
        </Box>

        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>優先連絡手段：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.preContact}</BodyText>
        </Box>

        {/* ご希望内容 */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>ご希望内容：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>
            {formData.教室の見学 && "教室の見学 "}
            {formData.プログラム体験 && "プログラム体験 "}
            {formData.まずは相談したい && "まずは相談したい"}
          </BodyText>
        </Box>

        {/* 所属施設 */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>所属施設：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.affiliation}</BodyText>
        </Box>

        {/* 利用時期のご希望 */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>利用時期のご希望：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.desiredTime}</BodyText>
        </Box>

        {/* お子様の学年 */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>お子様の学年：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.childGrade}</BodyText>
        </Box>

        {/* 郵便番号 */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>郵便番号：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.postalCode}</BodyText>
        </Box>

        {/* 住所 */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>住所：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.address}</BodyText>
        </Box>

        {/* Rukhはどこで知りましたか？ */}
        <Box css={classes.contentContainer}>
          <BodyText size={theme.typography.body}>Rukhはどこで知りましたか？：</BodyText>
          <BodyText size={theme.typography.bodyLarge} css={classes.contentText}>{formData.source}</BodyText>
        </Box>

        <DialogActions css={classes.dialogActions}>
            <CustomButton
            size={classes.customButtonSize}
            width={classes.customButtonWidth}
            variant="button"
            onClick={handleDialogClose}
            colorVariant={'grayBackground'}
            >
            戻る
            </CustomButton>
            <CustomButton
            size={classes.customButtonSize}
            width={classes.customButtonWidth}
            variant="button"
            onClick={handleDialogSubmit}
            >
            送信
            </CustomButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDialog;
