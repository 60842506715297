/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BodyText, H2Text, CustomButton } from '../../../../components/ui';
import { urls } from '../../../../config';
import { useNavigateToUrl } from '../../../../utils';
import SolutionSectionStyles from '../804SolutionSection/SolutionSectionStyles';

const BusinessSection2 = () => {
    const theme = useTheme();
    const classes = SolutionSectionStyles(theme);
    const navigateToUrl = useNavigateToUrl();

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <Box css={classes.content}>
                    <Box css={classes.textContainer}>
                        <H2Text size={theme.typography.title} css={classes.subTitle}>フィットネスや陸上教室での指導</H2Text>
                        <BodyText size={theme.typography.body} css={classes.description}>・フィットネス・陸上教室での指導サポート</BodyText>
                        <BodyText size={theme.typography.body} css={classes.description}>・成人向けの健康促進プログラムの提供</BodyText>
                    </Box>
                </Box>
                <Box css={classes.content}>
                    <Box css={classes.textContainer}>
                        <H2Text size={theme.typography.title} css={classes.subTitle}>高齢者向けの健康運動指導</H2Text>
                        <BodyText size={theme.typography.body} css={classes.description}>・社会福祉協議会やコミュニティーサロンでの高齢者向け運動指導</BodyText>
                        <BodyText size={theme.typography.body} css={classes.description}>・地域の社会福祉協議会およびコミュニティーサロンでのシニア向け健康促進プログラムの提供</BodyText>
                    </Box>
                </Box>
                <Box css={classes.content}>
                    <Box css={classes.textWithButtonContainer}>
                        <H2Text size={theme.typography.title} css={classes.subTitle}>大人のヒップホップ教室</H2Text>
                        <BodyText size={theme.typography.body} css={classes.description}>・初心者の大人向けの基礎から学べるヒップホップレッスン</BodyText>
                        <BodyText size={theme.typography.body} css={classes.description}>・リズム感や基本ステップを丁寧に指導し、楽しく体を動かせる環境を提供</BodyText>
                    </Box>
                    <Box css={classes.buttonContainer}>
                        <CustomButton
                            minWidth={classes.buttonMinWidth}
                            width={classes.buttonMinWidth}
                            onClick={() => navigateToUrl(urls.aboutAdultHipHop)}
                        >
                            <BodyText style={classes.smallText}>詳しくはこちら</BodyText>
                        </CustomButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default BusinessSection2;
