/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box, TextField } from '@mui/material';
import CustomTextFieldStyles from './CustomTextFieldStyles';
import { BodyText } from '../CustomText/CustomText';

const CustomTextField = ({ label, name, value, onChange, multiline = false, rows = 1, error = false, helperText = '' }) => {
  const theme = useTheme();
  const classes = CustomTextFieldStyles(theme);

  return (
    <Box css={classes.formControl}>
      <BodyText size={theme.typography.body} css={classes.label}>
        {label}
      </BodyText>
      <TextField
        size="small"
        name={name}
        value={value}
        onChange={onChange}
        fullWidth
        margin="normal"
        css={classes.textField}
        multiline={multiline}
        rows={rows}
        error={error}
        helperText={helperText}
      />
    </Box>
  );
};

export default CustomTextField;
