/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, Box } from '@mui/material';
import { faqData } from '../../../../config';
import { FAQ } from './components';
import FAQSectionStyles from './FAQSectionStyles';
import { H2Text } from '../../../../components/ui';

function FAQSection() {
    const theme = useTheme();
    const classes = FAQSectionStyles(theme);

    return (
        <Box css={classes.container} id="FAQ">
            <Box css={classes.wrapper}>
                <H2Text size={theme.typography.titleSmall} css={classes.title}>よくあるご質問</H2Text>
                {faqData.map((faq, index) => (
                    <FAQ key={index} question={faq.question} answer={faq.answer} supplement={faq.supplement} />
                ))}
            </Box>
        </Box>
    );
}

export default FAQSection;
