/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import AboutUsSectionStyles from './AboutUsSectionStyles';
import { BodyText, H2Text } from '../../../../components/ui';

const AboutUsSection = () => {
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const classes = AboutUsSectionStyles(theme, isMd, isSm);

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper} >
                <img src="/images/100TopPage/102AboutUsSectionBackGround.png" alt="About Us Section Background" css={classes.backgroundImage} />
                <Box css={classes.circle}>
                    <img css={classes.logo} src="/logo192.png" alt="Rukh Logo" />
                    <H2Text size={theme.typography.titleLarge} css={classes.title}>RUKHとは</H2Text>
                    <BodyText size={theme.typography.bodyLarge} css={classes.baseBodyTextStyle}>
                    合同会社RUKHの名前は、伝説の巨大な鳥「ロック鳥（Rukh）」と、代表の名前のスペルを組み合わせて作られています。ロック鳥は、中世の物語で力強さと大いなる存在として描かれています。この名前には、地域や健康を支える存在として、力強く未来を切り開いていくという思いが込められています。
                    </BodyText>
                </Box>
            </Box>
        </Box>
    );
};

export default AboutUsSection;
