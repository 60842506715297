import { css } from '@emotion/react';

const EventsSectionStyles = (theme, isMd, isSm) => ({
    container: css({
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '150px',
    }),
    wrapper: css({
        position: 'relative',
        background: `linear-gradient(to bottom, ${theme.palette.primary.sub}, ${theme.palette.primary.main})`,
        width: '80%',
        height: 'auto',
        maxWidth: '1000px',
        padding: '10px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '30px',
        boxShadow: theme.shadows[1],
    }),
    image: css({
        position: 'absolute',
        bottom: '0',
        width: '15%',
        aspectRatio: '5 / 4',
        [theme.breakpoints.down('sm')]: {
            bottom: 'auto',
            top: '-70px',
            right: '5px',
            width: '60px',
            height: '70px',
        },
    }),
    dancePersonImage: css({
        left: '0',
        [theme.breakpoints.down('sm')]: {
            left: '5px',
        },
    }),
    girlOnRocketImage: css({
        right: '0',
        [theme.breakpoints.down('sm')]: {
            right: '5px',
        },
    }),
    content: css({
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '15px 80px',
        border: `2px solid ${theme.palette.white.main}`,
        borderRadius: '30px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 20px',
        }
    }),
    title: css({
        textAlign: 'center',
        // fontSize: isSm ? '18px': isMd ? '20px' : '25px',
        paddingBottom: '10px',
        fontWeight: 'bold',
    }),
    message: css({
        marginBottom: '20px',
        textAlign: 'center',
    }),
    buttonSize: isMd ? 'small' : 'medium',
    buttonWidth: '60%',
    buttonMinWidth: '235px',
    bodyText: {
        fontSize: isSm ? '8px' : isMd ? '10px' : '14px',
        fontWeight: isSm ? 'bold' : isMd ? 'normal' : 'bold',
    },
});

export default EventsSectionStyles;
