import { css } from '@emotion/react';

const BottomContainerStyles = (theme) => ({
    bottomContainer: css({
        width: '100%',
        maxWidth: theme.width.max,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    }),
    socialIcons: css({
        display: 'flex',
        gap: '10px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
        },
    }),
    rightContainer: css({
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    }),
    privacyPolicy: css({
        color: theme.palette.white.main,
        fontWeight: 'bold',
        textDecoration: 'none',
    }),
});

export default BottomContainerStyles;
