import { css } from '@emotion/react';

const logoStyles = (theme) => ({
  logo: css({
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  }),
  logoImage: css({
    width: 'auto',
    height: '32px',
  }),
});

export default logoStyles;
