/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import ContactSectionStyles from './ContactSectionStyles';
import { BodyText, CustomButton, H2Text } from '../../ui';
import { useNavigateToUrl } from '../../../utils';
import { urls } from '../../../config';

const ContactSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const classes = ContactSectionStyles(theme, isMobile);
    const navigateToUrl = useNavigateToUrl();

    return (
        <Box css={classes.container}>
            <Box css={classes.wrapper}>
                <img src='/images/003ContactSection/questionPerson.png' alt='Person with a question mark' css={classes.image} />
                <H2Text size={theme.typography.titleSmall} css={classes.title}>お問い合わせ</H2Text>
                <BodyText szie={theme.typography.body} css={classes.message}>
                    当社についてのご不明点などは、こちらよりお問い合わせください。
                    また、登録インストラクターとしてご興味のある方からのお問い合わせもこちらで受け付けております。
                </BodyText>
                <CustomButton
                    size={classes.buttonSize}
                    minWidth={classes.buttonMinWidth}
                    width={classes.buttonWidth}
                    onClick={() => navigateToUrl(urls.contact)}
                >
                    <BodyText style={classes.bodyText}>お問い合わせはこちら</BodyText>
                </CustomButton>
            </Box>
        </Box>
    );
};

export default ContactSection;
