/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';

const BackgroundCircle = ({
    size = '200px', // デフォルトのサイズ
    colors = ['#C8F2BD', '#40D219'], // グラデーションの色
    position = { top: 'auto', right: 'auto', bottom: 'auto', left: 'auto' }, // 位置
    opacity = 0.5, // 不透明度
    zIndex = -1, // Zインデックス
}) => {
    const circleStyle = css({
        position: 'absolute',
        width: size,
        height: size,
        borderRadius: '50%',
        background: `linear-gradient(to bottom, ${colors[0]}, ${colors[1]})`,
        top: position.top,
        right: position.right,
        bottom: position.bottom,
        left: position.left,
        opacity: opacity,
        zIndex: zIndex,
        transition: 'width 0.3s, height 0.3s', // レスポンシブ対応用
        '@media (max-width: 1200px)': {
            width: `calc(${size} * 0.8)`,
            height: `calc(${size} * 0.8)`,
        },
        '@media (max-width: 768px)': {
            width: `calc(${size} * 0.6)`,
            height: `calc(${size} * 0.6)`,
        },
        '@media (max-width: 480px)': {
            width: `calc(${size} * 0.4)`,
            height: `calc(${size} * 0.4)`,
        },
    });

    return <div css={circleStyle} />;
};

export default BackgroundCircle;
